import React, { useState } from "react";
import { ObjectArrayTable, TableObject } from "../../../components/objectArrayTable/objectArrayTable";
import { InvitationsQuery } from "frontend-shared/generated/graphql";
import { SortColumnDescriptor } from "../../../components/objectArrayTable/objectArrayTableUtils";
import { Button, Stack, Typography } from "@mui/material";
import { formatDateSimple } from "frontend-shared/util/dateUtils";

export type InvitationUser = InvitationsQuery["invitedUsers"][0];

interface Props {
  data: InvitationUser[];
  dataDescription: string;
  buttons: { label: string; onClick: (obj: InvitationUser) => void }[];
}

export const InvitationUsersTable: React.FC<Props> = ({ data, dataDescription, buttons }) => {
  const [sortColumn, setSortColumn] = useState<SortColumnDescriptor>({ index: 0, ascending: true });
  const sortedData = [...data];
  const sortUsersFn = (a: InvitationUser, b: InvitationUser) => {
    const reverse = sortColumn.ascending ? 1 : -1;
    if (sortColumn.index === 0) {
      return a.displayName.localeCompare(b.displayName) * reverse;
    } else if (sortColumn.index === 1) {
      return a.email.localeCompare(b.email) * reverse;
    } else if (sortColumn.index === 2) {
      return a.createdDate.localeCompare(b.createdDate) * reverse;
    } else {
      return a.displayName.localeCompare(b.displayName) * reverse;
    }
  };
  sortedData.sort(sortUsersFn);

  return (
    <ObjectArrayTable
      dataDescription={dataDescription}
      title={["Name", "e-mail", "Created Date", "Actions"]}
      data={sortedData}
      cellNode={function (
        obj: InvitationUser,
        index: number,
        array: TableObject[],
      ): React.ReactNode | React.ReactNode[] {
        return [
          <Typography>{obj.displayName}</Typography>,
          <Typography>{obj.email}</Typography>,
          <Typography>{formatDateSimple(obj.createdDate)}</Typography>,
          <Stack direction="row" spacing={1}>
            {buttons.map((button) => (
              <Button
                onClick={() => {
                  button.onClick(obj);
                }}
              >
                {button.label}
              </Button>
            ))}
          </Stack>,
        ];
      }}
      onClick={function (id: string): void {}}
      headerSort={sortColumn}
      onHeaderClick={(colIndex) => {
        if (colIndex >= 3) {
          return;
        }
        setSortColumn({
          index: colIndex,
          ascending: colIndex === sortColumn.index ? !sortColumn.ascending : true,
        });
      }}
    />
  );
};
