import React, { useEffect } from "react";
import { useIdentitiesScreenQuery } from "frontend-shared/generated/graphql";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";

import { clearIdentitiesState, initializeIdentitiesState, selectIdentitiesState, setIdentitiesErrorMessage } from "./identitiesSlice";

export const IdentitiesStateLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading, error } = useIdentitiesScreenQuery({
    variables: {},
    fetchPolicy: "network-only",
  });
  const identitiesState = useAppSelector(selectIdentitiesState);

  // You need an effect to deal with loading state because you want to change state
  // via a dispatch while loading. You can't do that in the render function.
  useEffect(() => {
    if (loading) {
      dispatch(clearIdentitiesState());
    } else if (data && !identitiesState.initialized) {
      dispatch(initializeIdentitiesState({ data }));
    }
  });

  if (loading) {
    return <></>;
  } else if (error) {
    dispatch(setIdentitiesErrorMessage(error.message));
    return <></>;
  } else if (!data) {
    return <></>;
  } else if (!identitiesState.initialized) {
    return <></>;
  }

  return <></>;
};
