import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { SettingsQuery } from "../generated/graphql";

/**
 * Defines the slice, reducers, and actions for the Settings Page.
 */

export interface SettingsState {
  initialized: boolean;
  grayIsDefault: boolean;
  defaultStartTimeBlockNumber: number;
  defaultEndTimeBlockNumber: number;
}

const initialState: SettingsState = {
  initialized: false,
  grayIsDefault: true,
  defaultStartTimeBlockNumber: 0,
  defaultEndTimeBlockNumber: 0,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    clearSettingsState: (state) => {
      state.initialized = initialState.initialized;
      state.grayIsDefault = initialState.grayIsDefault;
      state.defaultStartTimeBlockNumber = initialState.defaultStartTimeBlockNumber;
      state.defaultEndTimeBlockNumber = initialState.defaultEndTimeBlockNumber;
    },
    initializeSettingsState: (
      state,
      action: PayloadAction<{
        data: SettingsQuery;
      }>
    ) => {
      const { data } = action.payload;
      state.initialized = true;
      state.grayIsDefault = data.userAccount.grayIsDefault;
      state.defaultStartTimeBlockNumber = data.userAccount.defaultStartTimeBlockNumber;
      state.defaultEndTimeBlockNumber = data.userAccount.defaultEndTimeBlockNumber;
    },
    setSettingsGrayIsDefault: (state, action: PayloadAction<{ grayIsDefault: boolean }>) => {
      state.grayIsDefault = action.payload.grayIsDefault;
    },
    setSettingsDefaultStartTimeBlockNumber: (state, action: PayloadAction<{ defaultStartTimeBlockNumber: number }>) => {
      state.defaultStartTimeBlockNumber = action.payload.defaultStartTimeBlockNumber;
    },
    setSettingsDefaultEndTimeBlockNumber: (state, action: PayloadAction<{ defaultEndTimeBlockNumber: number }>) => {
      state.defaultEndTimeBlockNumber = action.payload.defaultEndTimeBlockNumber;
    },
  },
});

export const {
  clearSettingsState,
  initializeSettingsState,
  setSettingsGrayIsDefault,
  setSettingsDefaultStartTimeBlockNumber,
  setSettingsDefaultEndTimeBlockNumber,
} = settingsSlice.actions;

export const selectSettingsState = (state: RootState) => state.settings as SettingsState;

export default settingsSlice.reducer;
