import React from "react";
import { Draggable } from "@hello-pangea/dnd";
import { Box, Button, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { PlanRole, PlanTask } from "frontend-shared/util/modelTypes";
import { colorForRole } from "frontend-shared/store/todayPageStateUtils";
import { Dictionary } from "@reduxjs/toolkit";
import { RoleMenu } from "./roleMenu";

interface Props {
  task: PlanTask;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  index: number;
  planTask: (task: PlanTask) => void;
  deleteTask: (task: PlanTask) => void;
  editTaskName: (task: PlanTask, newName: string) => void;
  setTaskRole(task: PlanTask, role: PlanRole): void;
  shouldBlockPlanUI: boolean;
  shouldBlockUnplannedUI: boolean;
  setEditingInProgress: (block: boolean) => void;
  inFilter: boolean;
}

export const DraggableTask: React.FC<Props> = ({
  task,
  index,
  planTask,
  roles,
  rolesList,
  deleteTask,
  editTaskName,
  setTaskRole,
  shouldBlockPlanUI,
  shouldBlockUnplannedUI,
  setEditingInProgress,
  inFilter,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [newName, setNewName] = React.useState(task.name);
  const [isRoleMenuOpen, setIsRoleMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onEditStart = () => {
    setIsEditing(true);
    setEditingInProgress(true);
  };

  const onEditEnd = () => {
    setIsEditing(false);
    setEditingInProgress(false);
  };

  const taskWidth = 300;

  return (
    <Draggable draggableId={task.id} index={index} isDragDisabled={shouldBlockUnplannedUI}>
      {(provided, snapshot) => (
        <Stack
          key={task.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          direction="row"
          spacing={1}
          alignItems="center"
          sx={inFilter ? { px: 1, backgroundColor: snapshot.isDragging ? "#f8f8f8" : undefined } : {}}
        >
          {inFilter ? (
            <>
              <Tooltip title={`${roles[task.roleId]?.name} task`} placement="top" arrow>
                <Box
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setIsRoleMenuOpen(true);
                  }}
                  sx={{ p: "8px", backgroundColor: colorForRole(roles, task.roleId) }}
                />
              </Tooltip>
              <RoleMenu
                open={isRoleMenuOpen}
                anchorEl={anchorEl}
                onClose={() => setIsRoleMenuOpen(false)}
                roles={rolesList}
                selectedRoleIndex={rolesList.findIndex((role) => role.id === task.roleId)}
                setSelectedRole={(roleIndex: number) => {
                  setAnchorEl(null);
                  setIsRoleMenuOpen(false);
                  setTaskRole(task, rolesList[roleIndex]);
                }}
              />
              {isEditing ? (
                <React.Fragment>
                  <TextField
                    autoFocus
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                    onBlur={() => {
                      // Give the button time to register the click if that's why we're losing focus
                      setTimeout(() => {
                        onEditEnd();
                      }, 250);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        editTaskName(task, newName);
                        onEditEnd();
                      } else if (e.key === "Escape") {
                        onEditEnd();
                      }
                    }}
                    sx={{ width: taskWidth }}
                    variant="standard"
                  ></TextField>
                  <Button
                    onClick={() => {
                      editTaskName(task, newName);
                      onEditEnd();
                    }}
                    disabled={newName === "" || newName === task.name}
                  >
                    OK
                  </Button>
                  <Button
                    onClick={() => {
                      onEditEnd();
                    }}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography
                    sx={{
                      cursor: "text",
                      width: taskWidth,
                      textDecoration: task.completed ? "line-through" : undefined,
                      overflowWrap: "anywhere",
                    }}
                    onClick={() => {
                      if (!shouldBlockUnplannedUI) {
                        setNewName(task.name);
                        onEditStart();
                      }
                    }}
                  >
                    {task.name}
                  </Typography>
                  <Box sx={{ visibility: shouldBlockPlanUI && shouldBlockUnplannedUI ? "hidden" : undefined }}>
                    <Button
                      onClick={() => {
                        setNewName(task.name);
                        onEditStart();
                      }}
                      disabled={shouldBlockUnplannedUI}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        planTask(task);
                      }}
                      disabled={shouldBlockUnplannedUI || shouldBlockPlanUI}
                      data-testid="PlanButton"
                    >
                      Plan
                    </Button>
                    <Button
                      onClick={() => {
                        deleteTask(task);
                      }}
                      disabled={shouldBlockUnplannedUI}
                    >
                      Delete
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </>
          ) : (
            <></>
          )}
        </Stack>
      )}
    </Draggable>
  );
};
