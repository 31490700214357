import { addTaskToPlan, deletePlannedTask, moveTaskToGroup, setPlannedTaskName, setTaskCompleted } from "frontend-shared/store/todayPageSlice";
import {
  requestDeletePlannedTask,
  requestMoveUserTaskToGroup,
  requestSetUserTaskCompleted,
  requestSetUserTaskName,
} from "frontend-shared/generated/graphqlWrappers";
import { showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import { DEFAULT_TIME_BLOCK_COUNT, PlanTask, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { optimisticUpdateAndServerRequestWithUndo } from "./api.utils";
import { uuidForId } from "frontend-shared/util/uuidUtils";
import { apiUpdateProjectScoreboard } from "./today.api.taskGroup";
import { TodayOptimisticAPIContext } from "./today.api.plan";

export const apiToggleTaskCompletion = (apiContext: TodayOptimisticAPIContext, task: PlanTask) => {
  const { client, dispatch } = apiContext;
  const oldCompleted = task.completed;
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setTaskCompleted({ id: task.id, completed: !task.completed }),
    request: requestSetUserTaskCompleted,
    variables: { taskId: task.id, completed: !task.completed },
    undo: setTaskCompleted({ id: task.id, completed: oldCompleted }),
  });
};

export const apiDeletePlannedTask = (apiContext: TodayOptimisticAPIContext, task: PlanTask) => {
  const taskGroup = apiContext.todayPageState.taskGroups.find((tg) => tg.tasks.some((t) => t.id === task.id));
  if (!taskGroup) {
    apiContext.dispatch(showSnackbarError("Task group not found"));
    return;
  }
  apiDeletePlannedTaskOnTaskGroup(apiContext, taskGroup, task);
};

export const apiDeletePlannedTaskOnTaskGroup = (apiContext: TodayOptimisticAPIContext, taskGroup: PlanTaskGroup, task: PlanTask) => {
  const { client, dispatch, settingsState } = apiContext;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: deletePlannedTask({ taskId: task.id }),
    request: requestDeletePlannedTask,
    variables: { taskId: task.id },
    onEnd: () => {
      apiUpdateProjectScoreboard(apiContext, taskGroup.projectId);
    },
    undo: addTaskToPlan({
      taskGroupId: taskGroup.id,
      taskGroupOrder: taskGroup.order,
      task,
      grayIsDefault: settingsState.grayIsDefault,
      recoveryData: {
        taskGroupName: taskGroup.name,
        roleId: taskGroup.roleId,
        timeBlockCount: taskGroup.timeBlockCount,
        completedTimeBlockCount: taskGroup.completedTimeBlockCount,
        routineId: taskGroup.routineId,
        projectId: taskGroup.projectId,
        roleIdentityId: taskGroup.roleIdentityId,
        isGrayTime: taskGroup.isGrayTime,
      },
    }),
  });
};

export const apiUngroupTask = (apiContext: TodayOptimisticAPIContext, taskGroup: PlanTaskGroup, task: PlanTask) => {
  const { client, todayPageState, dispatch } = apiContext;
  const oldTaskGroupId = taskGroup.id;
  const newTaskGroupId = uuidForId();

  const taskGroupIndex = todayPageState.taskGroups.findIndex((tg) => tg.id === taskGroup.id);
  const taskGroupOrder = taskGroup.order;
  const nextTaskGroupOrder = todayPageState.taskGroups[taskGroupIndex + 1]?.order ?? taskGroupOrder + 2000;
  const newTaskGroupOrder = (taskGroupOrder + nextTaskGroupOrder) / 2;

  dispatch(
    moveTaskToGroup({
      taskId: task.id,
      taskGroupId: newTaskGroupId,
      groupOrder: newTaskGroupOrder,
      taskOrder: task.order,
      timeBlockCount: DEFAULT_TIME_BLOCK_COUNT,
      completedTimeBlockCount: 0,
      // New taskgroups stay in the project, but not in the routine
      projectId: taskGroup.projectId,
      isGrayTime: taskGroup.isGrayTime,
    })
  );
  requestMoveUserTaskToGroup(
    client,
    {
      taskId: task.id,
      planId: todayPageState.dailyPlanId,
      dailyPlanTaskGroupId: newTaskGroupId,
      groupOrder: newTaskGroupOrder,
      taskOrder: task.order,
    },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(
        moveTaskToGroup({
          taskId: task.id,
          taskGroupId: oldTaskGroupId,
          groupOrder: taskGroup.order,
          taskOrder: task.order,
          timeBlockCount: taskGroup.timeBlockCount,
          completedTimeBlockCount: taskGroup.completedTimeBlockCount,
          routineId: taskGroup.routineId,
          projectId: taskGroup.projectId,
          isGrayTime: taskGroup.isGrayTime,
        })
      );
    }
  );
};

export const apiEditPlannedTaskName = (apiContext: TodayOptimisticAPIContext, task: PlanTask, newName: string) => {
  const { client, dispatch } = apiContext;
  const oldTaskName = task.name;
  dispatch(setPlannedTaskName({ id: task.id, name: newName }));
  requestSetUserTaskName(
    client,
    { taskId: task.id, name: newName },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(setPlannedTaskName({ id: task.id, name: oldTaskName }));
    }
  );
};
