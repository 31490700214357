import { Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import { RoleMenu } from "./roleMenu";
import { PlanRole } from "frontend-shared/util/modelTypes";
import { Dictionary } from "@reduxjs/toolkit";
import { colorForRole } from "frontend-shared/store/todayPageStateUtils";

interface Props {
  addTaskButtonDisabled: boolean;
  addTask: (name: string) => void;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  defaultRoleForAddTask: PlanRole;
  setDefaultRoleForAddTask: (role: PlanRole) => void;
}

export const AddUnplannedTask: React.FC<Props> = ({
  addTaskButtonDisabled,
  addTask,
  roles,
  rolesList,
  defaultRoleForAddTask,
  setDefaultRoleForAddTask,
}) => {
  const [taskName, setTaskName] = React.useState("");
  const [isRoleMenuOpen, setIsRoleMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const roleColor = colorForRole(roles, defaultRoleForAddTask.id);
  const addTaskDisabled = addTaskButtonDisabled || taskName === "";
  const onAddTask = () => {
    if (!addTaskDisabled) {
      addTask(taskName);
      setTaskName("");
      inputRef.current?.focus();
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ mx: "auto" }}>
      <Box
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsRoleMenuOpen(true);
        }}
        sx={{ p: "12px", backgroundColor: roleColor }}
      />
      <TextField
        inputRef={inputRef}
        sx={{ width: "300px", flexGrow: 1 }}
        value={taskName}
        placeholder="new backlog task"
        onChange={(e) => {
          setTaskName(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onAddTask();
          }
        }}
      />
      <RoleMenu
        open={isRoleMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setIsRoleMenuOpen(false)}
        roles={rolesList}
        selectedRoleIndex={rolesList.findIndex((r) => r.id === defaultRoleForAddTask.id)}
        setSelectedRole={(roleIndex: number) => {
          setAnchorEl(null);
          setIsRoleMenuOpen(false);
          setDefaultRoleForAddTask(rolesList[roleIndex]);
        }}
      />
      <Button disabled={addTaskDisabled} sx={{ backgroundColor: roleColor }} variant="contained" onClick={onAddTask}>
        Add Task
      </Button>
    </Stack>
  );
};
