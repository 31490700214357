import { Dictionary, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IdentitiesScreenQuery, RoleIdentityDataForScoreboardResponse } from "../generated/graphql";
import { PlanRole, PlanRoleIdentity } from "../util/modelTypes";
import { RootState } from "./store";

export interface IdentitiesState {
  initialized: boolean;
  errorMessage?: string;
  roleIdentities: PlanRoleIdentity[];
  roles: Dictionary<PlanRole>;
}

const initialState: IdentitiesState = {
  initialized: false,
  errorMessage: undefined,
  roleIdentities: [],
  roles: {},
};

export const routinesSlice = createSlice({
  name: "identities",
  initialState,
  reducers: {
    clearIdentitiesState: (state) => {
      state.initialized = false;
      state.roleIdentities = initialState.roleIdentities;
      state.roles = initialState.roles;
      state.errorMessage = initialState.errorMessage;
    },
    initializeIdentitiesState: (state, action: PayloadAction<{ data: IdentitiesScreenQuery }>) => {
      const { data } = action.payload;
      state.initialized = true;
      state.roleIdentities = action.payload.data.userAccount.roleIdentities.map((roleIdentity) => ({
        id: roleIdentity.id,
        name: roleIdentity.name,
        roleId: roleIdentity.role.id,
        color: roleIdentity.color,
        weeklyPaceTimeBlockCount: roleIdentity.weeklyPaceTimeBlockCount,
        order: roleIdentity.order,
      }));
      state.roles = data.userAccount.roles.reduce((roles, role) => {
        roles[role.id] = role;
        return roles;
      }, {} as Dictionary<PlanRole>);
      state.errorMessage = initialState.errorMessage;
    },
    addNewRoleIdentity: (
      state,
      action: PayloadAction<{
        roleIdentityId: string;
        roleId: string;
        name: string;
        color: string;
        weeklyPaceTimeBlockCount: number;
        order: number;
      }>
    ) => {
      state.roleIdentities.push({ ...action.payload, id: action.payload.roleIdentityId });
    },
    removeRoleIdentity: (state, action: PayloadAction<{ roleIdentityId: string }>) => {
      state.roleIdentities = state.roleIdentities.filter((roleIdentity) => roleIdentity.id !== action.payload.roleIdentityId);
      // TODO: update today
      // for (const group of state.taskGroups) {
      //   if (group.roleIdentityId === action.payload.roleIdentityId) {
      //     group.roleIdentityId = undefined;
      //   }
      // }
    },
    updateRoleIdentity: (
      state,
      action: PayloadAction<{
        roleIdentityId: string;
        name: string;
        color: string;
        weeklyPaceTimeBlockCount: number;
        order: number;
      }>
    ) => {
      const roleIdentityIndex = state.roleIdentities.findIndex((roleIdentity) => roleIdentity.id === action.payload.roleIdentityId);
      if (roleIdentityIndex !== -1) {
        const roleIdentity = state.roleIdentities.splice(roleIdentityIndex, 1)[0];
        roleIdentity.name = action.payload.name;
        roleIdentity.color = action.payload.color;
        roleIdentity.weeklyPaceTimeBlockCount = action.payload.weeklyPaceTimeBlockCount;
        roleIdentity.order = action.payload.order;

        // Put roleIdentity in the right order
        const newRoleIdentityIndex = state.roleIdentities.findIndex((roleIdentity) => roleIdentity.order > action.payload.order);
        if (newRoleIdentityIndex >= 0) {
          state.roleIdentities.splice(newRoleIdentityIndex, 0, roleIdentity);
        } else {
          state.roleIdentities.push(roleIdentity);
        }
      }
    },
    setIdentitiesErrorMessage: (state, action: PayloadAction<string>) => {
      state.initialized = false;
      state.errorMessage = action.payload;
    },
  },
});

export const {
  clearIdentitiesState,
  initializeIdentitiesState,
  addNewRoleIdentity,
  removeRoleIdentity,
  updateRoleIdentity,
  setIdentitiesErrorMessage,
} = routinesSlice.actions;

export const selectIdentitiesState = (state: RootState) => state.identities as IdentitiesState;

export default routinesSlice.reducer;
