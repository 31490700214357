import { ColorTranslator } from "colortranslator";

// hexcolor must be in #ffffff format
export function textColorForBackgroundColor(hexcolor: string) {
  // Code from https://stackoverflow.com/a/11868398/3937
  // Converted to TypeScript
  if (hexcolor.length !== 7 && hexcolor[0] !== "#") {
    return "black";
  }
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export function isHexColor(str: string) {
  return str.match(/^#[a-fA-F0-9]{6}$/i) !== null || str.match(/^#[a-fA-F0-9]{3}$/i) !== null;
}

// hexcolor must be in #ffffff format
// percent is a number between 0 and 100
export function lightenColor(hexcolor: string | undefined, percent?: number): string | undefined {
  if (hexcolor === undefined) {
    return undefined;
  }
  const h = new ColorTranslator(hexcolor).H;
  const s = new ColorTranslator(hexcolor).S;
  const l = new ColorTranslator(hexcolor).L;
  const rgb = new ColorTranslator(`hsl(${h}, ${s}%, ${l + (percent ?? 10)}%)`).RGB;
  return rgb;
}
