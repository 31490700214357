import { createSlice, Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { TodayPageQuery } from "../generated/graphql";
import { TimerState } from "../util/timerState";
import { timeForTimerType, TimerType } from "../util/timerType";
import {
  createRoleBlockType,
  DEFAULT_MAX_TIME_BLOCK_COUNT,
  parsePlannedBlockType,
  PlanProject,
  PlanProjectDataForScoreboard,
  PlanRole,
  PlanRoleIdentity,
  PlanTask,
  PlanTaskGroup,
  ROLE_BLOCK_BREAK,
  RoleBlockType,
  RoutinePlanTaskGroup,
} from "frontend-shared/util/modelTypes";
import {
  setTimerStateUtil,
  recalculateRoleCountsFromDictionary,
  addTaskToPlanUtil,
  removeTaskFromPlanUtil,
  tryChangeMeeting,
  setGuideRail,
} from "./todayPageStateUtils";
import { range } from "frontend-shared/util/arrayUtils";
import { localToggleSelectedRole } from "../api/today.localApi.backlog";

/**
 * Defines the slice, reducers, and actions for the Today Page.
 */

export interface TodayPageState {
  initialized: boolean;
  date: string;
  roles: Dictionary<PlanRole>;
  selectedRoles: Dictionary<PlanRole>;
  roleBlockCounts: Dictionary<number>;
  defaultRoleId: string;
  timerRoleId: string;
  roleViewCompleted: string;
  dailyPlanId: string;
  taskGroups: PlanTaskGroup[];
  startTimeBlockNumber: number;
  endTimeBlockNumber: number;
  timerState: TimerState;
  timerType: TimerType;
  secondsLeftOnTimer: number;
  dateOfLastTickISOString: string;
  plannedBlocks: RoleBlockType[];
  addTaskInFlight: boolean;
  isEditingTaskId: string;
  editingInProgress: boolean;
  autoAddModeGroup: string;
  projects: Dictionary<PlanProject>;
  roleIdentities: Dictionary<PlanRoleIdentity>;
  selectedTaskGroupId?: string;
}

const initialState: TodayPageState = {
  initialized: false,
  date: "",
  roles: {},
  selectedRoles: {},
  roleBlockCounts: {},
  defaultRoleId: "",
  timerRoleId: "",
  roleViewCompleted: "Normal",
  dailyPlanId: "",
  taskGroups: [],
  startTimeBlockNumber: 0,
  endTimeBlockNumber: DEFAULT_MAX_TIME_BLOCK_COUNT - 1,
  timerState: TimerState.Stopped,
  timerType: TimerType.Work,
  secondsLeftOnTimer: 0,
  dateOfLastTickISOString: new Date().toISOString(),
  plannedBlocks: range(DEFAULT_MAX_TIME_BLOCK_COUNT).map((_) => ROLE_BLOCK_BREAK),
  addTaskInFlight: false,
  isEditingTaskId: "",
  editingInProgress: false,
  autoAddModeGroup: "",
  projects: {},
  roleIdentities: {},
  selectedTaskGroupId: undefined,
};

export const todayPageSlice = createSlice({
  name: "todayPage",
  initialState,
  reducers: {
    clearTodayPageState: (state) => {
      state.initialized = initialState.initialized;
      state.date = initialState.date;
      state.roles = initialState.roles;
      state.selectedRoles = initialState.selectedRoles;
      state.roleBlockCounts = initialState.roleBlockCounts;
      state.defaultRoleId = initialState.defaultRoleId;
      state.timerRoleId = initialState.timerRoleId;
      state.roleViewCompleted = initialState.roleViewCompleted;
      state.dailyPlanId = initialState.dailyPlanId;
      state.taskGroups = initialState.taskGroups;
      state.startTimeBlockNumber = initialState.startTimeBlockNumber;
      state.endTimeBlockNumber = initialState.endTimeBlockNumber;
      state.timerState = initialState.timerState;
      state.timerType = initialState.timerType;
      state.secondsLeftOnTimer = initialState.secondsLeftOnTimer;
      state.dateOfLastTickISOString = initialState.dateOfLastTickISOString;
      state.plannedBlocks = initialState.plannedBlocks;
      state.addTaskInFlight = initialState.addTaskInFlight;
      state.isEditingTaskId = initialState.isEditingTaskId;
      state.editingInProgress = initialState.editingInProgress;
      state.autoAddModeGroup = initialState.autoAddModeGroup;
      state.projects = initialState.projects;
      state.roleIdentities = initialState.roleIdentities;
      state.selectedTaskGroupId = initialState.selectedTaskGroupId;
    },
    initializeTodayPage: (
      state,
      action: PayloadAction<{
        data: TodayPageQuery;
        date: string;
        secondsLeft: number;
        timerState: TimerState;
        dateOfLastTickISOString: string;
        timerType: TimerType;
        selectedRoleIds: string[] | undefined;
        defaultRoleId: string;
        timerRoleId: string;
        roleViewCompleted: string;
      }>
    ) => {
      const {
        data,
        date,
        secondsLeft,
        timerState,
        dateOfLastTickISOString,
        timerType,
        selectedRoleIds,
        defaultRoleId,
        timerRoleId,
        roleViewCompleted,
      } = action.payload;
      // log the JSON string conversion of the data variable
      state.initialized = true;
      state.date = date;

      state.roles = data.userAccount.roles.reduce((roles, role) => {
        roles[role.id] = role;
        return roles;
      }, {} as Dictionary<PlanRole>);
      const selectedRoles =
        selectedRoleIds === undefined
          ? state.roles
          : selectedRoleIds.reduce((roles, roleId) => {
              const role = state.roles[roleId];
              if (role !== undefined) {
                roles[roleId] = role;
              }
              return roles;
            }, {} as Dictionary<PlanRole>);

      // If the selected roles length doesn't match the length of ids, then it
      // might be that they have selected roles that are deleted or that they have
      // a cookie from another user (if they logout/login).
      // In this case, we should reset the selected roles to be all roles.
      if (Object.values(selectedRoles).length !== selectedRoleIds?.length) {
        state.selectedRoles = state.roles;
      } else {
        state.selectedRoles = selectedRoles;
      }
      const firstUserRoleId = data.userAccount.roles[0].id;
      state.defaultRoleId = defaultRoleId !== "" && state.roles[defaultRoleId] !== undefined ? defaultRoleId : firstUserRoleId;
      state.dailyPlanId = data.userDailyPlanForDate?.id ?? "";
      state.timerRoleId = timerRoleId !== "" && state.roles[timerRoleId] !== undefined ? timerRoleId : firstUserRoleId;
      state.roleViewCompleted = roleViewCompleted;

      const taskGroups = (data.userDailyPlanForDate?.taskGroups ?? []).map((group) => {
        const roleId = group.role.id;

        const tasks =
          group.tasks?.map((task) => {
            return {
              id: task.id,
              name: task.name,
              order: task.order,
              completed: task.completed,
              roleId,
            };
          }) ?? [];

        return {
          id: group.id,
          name: group.name ?? null,
          roleId,
          timeBlockCount: group.timeBlockCount,
          completedTimeBlockCount: group.completedTimeBlockCount,
          startTimeBlockNumber: group.startTimeBlockNumber ?? undefined,
          order: group.order,
          collapsed: group.collapsed,
          isGrayTime: group.isGrayTime,
          tasks: tasks,
          routineId: group.routine?.id,
          projectId: group.project?.id,
          roleIdentityId: group.roleIdentity?.id,
        };
      });
      state.taskGroups = taskGroups;

      state.startTimeBlockNumber = data.userDailyPlanForDate?.startTimeBlockNumber ?? initialState.startTimeBlockNumber;
      state.endTimeBlockNumber = data.userDailyPlanForDate?.endTimeBlockNumber ?? initialState.endTimeBlockNumber;

      state.timerType = timerType;
      state.secondsLeftOnTimer = secondsLeft >= 0 ? secondsLeft : timeForTimerType(timerType);
      state.timerState = timerState;
      state.dateOfLastTickISOString = dateOfLastTickISOString;
      for (const block of data.userDailyPlanForDate?.plannedBlocks ?? []) {
        const role = state.roles[block.role.id];
        if (role) {
          state.plannedBlocks[block.blockNumber] = createRoleBlockType(role, parsePlannedBlockType(block.type));
        }
      }
      recalculateRoleCountsFromDictionary(state, state.roles);

      state.projects = data.userAccount.projects.reduce((projects, project) => {
        projects[project.id] = {
          id: project.id,
          name: project.name,
          roleId: project.role.id,
          color: project.color,
          showOnScoreboard: project.showOnScoreboard,
          startDate: project.startDate,
          endDate: project.endDate,
          weeklyPaceTimeBlockCount: project.weeklyPaceTimeBlockCount,
          weekStartsDayOfWeek: project.weekStartsDayOfWeek,
          dataForScoreboard: project.dataForScoreboard,
        };
        return projects;
      }, {} as Dictionary<PlanProject>);

      state.roleIdentities = data.userAccount.roleIdentities.reduce((roleIdentities, roleIdentity) => {
        roleIdentities[roleIdentity.id] = {
          id: roleIdentity.id,
          name: roleIdentity.name,
          color: roleIdentity.color,
          order: roleIdentity.order,
          roleId: roleIdentity.role.id,
          weeklyPaceTimeBlockCount: roleIdentity.weeklyPaceTimeBlockCount,
        };
        return roleIdentities;
      }, {} as Dictionary<PlanRoleIdentity>);
    },
    setDailyPlanId: (state, action: PayloadAction<{ dailyPlanId: string }>) => {
      state.dailyPlanId = action.payload.dailyPlanId;
    },

    setStartTimeBlockNumber: (state, action: PayloadAction<{ startTimeBlockNumber: number }>) => {
      state.startTimeBlockNumber = action.payload.startTimeBlockNumber;
      recalculateRoleCountsFromDictionary(state, state.roles);
    },
    setEndTimeBlockNumber: (state, action: PayloadAction<{ endTimeBlockNumber: number }>) => {
      state.endTimeBlockNumber = action.payload.endTimeBlockNumber;
      recalculateRoleCountsFromDictionary(state, state.roles);
    },
    setTimeBlockNumbers: (state, action: PayloadAction<{ startTimeBlockNumber: number; endTimeBlockNumber: number }>) => {
      state.startTimeBlockNumber = action.payload.startTimeBlockNumber;
      state.endTimeBlockNumber = action.payload.endTimeBlockNumber;
      recalculateRoleCountsFromDictionary(state, state.roles);
    },

    setPlanBlock: (state, action: PayloadAction<{ blockNumber: number; roleId?: string; plannedBlockType?: string }>) => {
      const role = action.payload.roleId ? state.roles[action.payload.roleId] : undefined;
      if (role && action.payload.plannedBlockType) {
        state.plannedBlocks[action.payload.blockNumber] = createRoleBlockType(role, parsePlannedBlockType(action.payload.plannedBlockType));
        recalculateRoleCountsFromDictionary(state, state.roles);
      } else {
        state.plannedBlocks[action.payload.blockNumber] = ROLE_BLOCK_BREAK;
        recalculateRoleCountsFromDictionary(state, state.roles);
      }
    },
    setTaskGroupRole: (state, action: PayloadAction<{ taskGroupId: string; roleId: string }>) => {
      const group = state.taskGroups.find((group) => group.id === action.payload.taskGroupId);
      if (!group) {
        return;
      }
      const role = state.roles[action.payload.roleId];
      if (!role) {
        return;
      }
      if (tryChangeMeeting(state, role, group.startTimeBlockNumber, group.startTimeBlockNumber, group.timeBlockCount, group.timeBlockCount)) {
        group.roleId = action.payload.roleId;
        group.tasks.forEach((task) => (task.roleId = action.payload.roleId));
        recalculateRoleCountsFromDictionary(state, state.roles);
      }
    },
    setTaskGroupTimeBlockCount: (state, action: PayloadAction<{ id: string; timeBlockCount: number }>) => {
      const { id, timeBlockCount } = action.payload;
      const group = state.taskGroups.find((group) => group.id === id);
      if (!group) {
        return;
      }
      const role = state.roles[group.roleId];
      if (!role) {
        return;
      }
      const oldTimeBlockCount = group.timeBlockCount;
      const startTimeBlockNumber = group.startTimeBlockNumber;
      if (tryChangeMeeting(state, role, startTimeBlockNumber, startTimeBlockNumber, timeBlockCount, oldTimeBlockCount)) {
        group.timeBlockCount = timeBlockCount;
      }
    },
    setTaskGroupCompletedTimeBlockCount: (state, action: PayloadAction<{ id: string; completedTimeBlockCount: number }>) => {
      const group = state.taskGroups.find((group) => group.id === action.payload.id);
      if (group) {
        group.completedTimeBlockCount = action.payload.completedTimeBlockCount;
      }
    },
    setTaskGroupStartTimeBlockNumber: (state, action: PayloadAction<{ id: string; startTimeBlockNumber?: number }>) => {
      const { id, startTimeBlockNumber } = action.payload;
      const group = state.taskGroups.find((group) => group.id === id);
      if (!group) {
        return;
      }
      const role = state.roles[group.roleId];
      if (!role) {
        return;
      }

      const timeBlockCount = group.timeBlockCount;
      const oldStartTimeBlockNumber = group.startTimeBlockNumber;
      if (tryChangeMeeting(state, role, startTimeBlockNumber, oldStartTimeBlockNumber, timeBlockCount, timeBlockCount)) {
        group.startTimeBlockNumber = startTimeBlockNumber;
      }
    },
    addNewProject: (
      state,
      action: PayloadAction<{
        projectId: string;
        name: string;
        roleId: string;
        color: string;
        showOnScoreboard: boolean;
        startDate: string;
        endDate: string;
        weeklyPaceTimeBlockCount: number;
        weekStartsDayOfWeek: number;
      }>
    ) => {
      state.projects[action.payload.projectId] = {
        id: action.payload.projectId,
        name: action.payload.name,
        roleId: action.payload.roleId,
        color: action.payload.color,
        showOnScoreboard: action.payload.showOnScoreboard,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        weeklyPaceTimeBlockCount: action.payload.weeklyPaceTimeBlockCount,
        weekStartsDayOfWeek: action.payload.weekStartsDayOfWeek,
        dataForScoreboard: [],
      };
    },
    removeProject: (state, action: PayloadAction<{ projectId: string }>) => {
      delete state.projects[action.payload.projectId];
      for (const group of state.taskGroups) {
        if (group.projectId === action.payload.projectId) {
          group.projectId = undefined;
        }
      }
    },
    updateProject: (
      state,
      action: PayloadAction<{
        projectId: string;
        name: string;
        color: string;
        showOnScoreboard: boolean;
        startDate: string;
        endDate: string;
        weeklyPaceTimeBlockCount: number;
        weekStartsDayOfWeek: number;
      }>
    ) => {
      const project = state.projects[action.payload.projectId];
      if (project) {
        project.name = action.payload.name;
        project.color = action.payload.color;
        project.showOnScoreboard = action.payload.showOnScoreboard;
        project.startDate = action.payload.startDate;
        project.endDate = action.payload.endDate;
        project.weeklyPaceTimeBlockCount = action.payload.weeklyPaceTimeBlockCount;
        project.weekStartsDayOfWeek = action.payload.weekStartsDayOfWeek;
      }
    },
    updateProjectScoreboard: (state, action: PayloadAction<{ projectId: string; dataForScoreboard: PlanProjectDataForScoreboard[] }>) => {
      const project = state.projects[action.payload.projectId];
      if (project) {
        project.dataForScoreboard = action.payload.dataForScoreboard;
      }
    },
    setTaskGroupProject: (state, action: PayloadAction<{ taskGroupId: string; projectId: string | undefined }>) => {
      const group = state.taskGroups.find((group) => group.id === action.payload.taskGroupId);
      if (group) {
        group.projectId = action.payload.projectId;
      }
    },
    setTaskGroupRoleIdentity: (state, action: PayloadAction<{ taskGroupId: string; roleIdentityId: string | undefined }>) => {
      const group = state.taskGroups.find((group) => group.id === action.payload.taskGroupId);
      if (group) {
        group.roleIdentityId = action.payload.roleIdentityId;
      }
    },
    setTaskGroupCollapsed: (state, action: PayloadAction<{ taskGroupId: string; collapsed: boolean }>) => {
      const group = state.taskGroups.find((group) => group.id === action.payload.taskGroupId);
      if (group) {
        group.collapsed = action.payload.collapsed;
      }
    },
    setTaskGroupName: (state, action: PayloadAction<{ taskGroupId: string; name: string | null }>) => {
      const group = state.taskGroups.find((group) => group.id === action.payload.taskGroupId);
      if (group) {
        group.name = action.payload.name;
      }
    },
    setTaskGroupGrayTime: (state, action: PayloadAction<{ taskGroupId: string; isGrayTime: boolean }>) => {
      const group = state.taskGroups.find((group) => group.id === action.payload.taskGroupId);
      if (group) {
        group.isGrayTime = action.payload.isGrayTime;
      }
    },
    setAllTaskGroupsCollapsed: (state, action: PayloadAction<{ collapsed: boolean }>) => {
      state.taskGroups.forEach((group) => (group.collapsed = action.payload.collapsed));
    },
    setTaskCompleted: (state, action: PayloadAction<{ id: string; completed: boolean }>) => {
      const task = state.taskGroups.map((group) => group.tasks.find((task) => task.id === action.payload.id)).find((task) => task);
      if (task) {
        task.completed = action.payload.completed;
      }
    },
    moveTaskToGroup: (
      state,
      action: PayloadAction<{
        taskId: string;
        taskGroupId: string;
        taskOrder: number;
        groupOrder: number;
        timeBlockCount: number;
        completedTimeBlockCount: number;
        startTimeBlockNumber?: number;
        routineId?: string;
        projectId?: string;
        roleIdentityId?: string;
        isGrayTime: boolean;
      }>
    ) => {
      const {
        taskId,
        taskGroupId,
        groupOrder,
        taskOrder,
        timeBlockCount,
        completedTimeBlockCount,
        startTimeBlockNumber,
        routineId,
        projectId,
        roleIdentityId,
        isGrayTime,
      } = action.payload;
      const oldTaskGroup = state.taskGroups.find((group) => group.tasks.find((task) => task.id === taskId));

      if (!oldTaskGroup) {
        console.log("moveTaskToGroup: Task not found in any group");
        return;
      }
      const task = oldTaskGroup.tasks.find((task) => task.id === taskId);
      if (!task) {
        console.log("moveTaskToGroup: Task not found in any group");
        return;
      }

      // Remove task. If oldTaskGroup has no tasks, remove it too (and update guiderail).
      oldTaskGroup.tasks = oldTaskGroup.tasks.filter((task) => task.id !== taskId);
      const oldGroupIsEmpty = oldTaskGroup.tasks.length === 0;
      if (oldGroupIsEmpty) {
        state.taskGroups = state.taskGroups.filter((group) => group.id !== oldTaskGroup.id);
        setGuideRail(state, oldTaskGroup.startTimeBlockNumber, oldTaskGroup.timeBlockCount, ROLE_BLOCK_BREAK);
      }

      // If the task is being moved to an existing group, use it. Otherwise, create a new one.
      const newExistingTaskGroup = state.taskGroups.find((group) => group.id === taskGroupId);

      // If the new group exists and does not have a routine, project, roleIdentity or has no name,
      // it gets it from the old group if we are going to delete it.
      if (newExistingTaskGroup && oldGroupIsEmpty) {
        if (!newExistingTaskGroup.projectId) {
          newExistingTaskGroup.projectId = oldTaskGroup.projectId;
        }
        if (!newExistingTaskGroup.roleIdentityId) {
          newExistingTaskGroup.roleIdentityId = oldTaskGroup.roleIdentityId;
        }
        if (!newExistingTaskGroup.routineId) {
          newExistingTaskGroup.routineId = oldTaskGroup.routineId;
        }
        if (!newExistingTaskGroup.name) {
          newExistingTaskGroup.name = oldTaskGroup.name;
        }
      }

      // Create the new task group if we are not moving to an existing one
      const newTaskGroup = newExistingTaskGroup ?? {
        id: taskGroupId,
        name: oldTaskGroup.name,
        roleId: oldTaskGroup.roleId,
        timeBlockCount,
        completedTimeBlockCount,
        startTimeBlockNumber,
        order: groupOrder,
        collapsed: false,
        isGrayTime: isGrayTime,
        tasks: [],
        routineId,
        projectId,
        roleIdentityId,
      };

      // Put the task in the group in the right order
      task.order = taskOrder;
      const taskIndex = newTaskGroup.tasks.findIndex((t) => t.order > task.order);
      if (taskIndex >= 0) {
        newTaskGroup.tasks.splice(taskIndex, 0, task);
      } else {
        newTaskGroup.tasks.push(task);
      }

      // If this is a new group, add it to the list in the right order
      if (!newExistingTaskGroup) {
        const groupIndex = state.taskGroups.findIndex((group) => group.order > groupOrder);
        if (groupIndex >= 0) {
          state.taskGroups.splice(groupIndex, 0, newTaskGroup);
        } else {
          state.taskGroups.push(newTaskGroup);
        }
      }
    },
    addTaskToPlan: (
      state,
      action: PayloadAction<{
        taskGroupId: string;
        taskGroupOrder: number;
        task: PlanTask;
        grayIsDefault: boolean;
        recoveryData?: {
          taskGroupName: string | null;
          roleId: string;
          timeBlockCount: number;
          completedTimeBlockCount: number;
          startTimeBlockNumber?: number;
          routineId?: string;
          projectId?: string;
          roleIdentityId?: string;
          isGrayTime: boolean;
        };
      }>
    ) => {
      addTaskToPlanUtil(state, action);
    },
    addNewTaskToPlan: (
      state,
      action: PayloadAction<{
        taskGroupId: string;
        taskGroupOrder: number;
        taskId: string;
        roleId: string;
        name: string;
        order: number;
        grayIsDefault: boolean;
        recoveryData?: {
          taskGroupName: string | null;
          roleId: string;
          timeBlockCount: number;
          completedTimeBlockCount: number;
          startTimeBlockNumber?: number;
          routineId?: string;
          projectId?: string;
          roleIdentityId?: string;
          isGrayTime: boolean;
        };
      }>
    ) => {
      const task = {
        id: action.payload.taskId,
        name: action.payload.name,
        completed: false,
        order: action.payload.order,
        roleId: action.payload.roleId,
      };
      addTaskToPlanUtil(state, { type: action.type, payload: { ...action.payload, task } });
    },
    unplanTask: (state, action: PayloadAction<{ taskId: string; removeEmptyGroup?: boolean }>) => {
      const removeEmptyGroup = action.payload.removeEmptyGroup === undefined ? false : action.payload.removeEmptyGroup;
      const taskGroup = state.taskGroups.find((group) => group.tasks.find((task) => task.id === action.payload.taskId));
      if (taskGroup) {
        removeTaskFromPlanUtil(state, action, removeEmptyGroup);
      }
    },
    setPlannedTaskName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const taskGroup = state.taskGroups.find((group) => group.tasks.find((task) => task.id === action.payload.id));
      if (taskGroup) {
        const task = taskGroup.tasks.find((task) => task.id === action.payload.id);
        if (task) {
          task.name = action.payload.name;
        }
      }
    },
    deletePlannedTask: (state, action: PayloadAction<{ taskId: string; removeEmptyGroup?: boolean }>) => {
      const removeEmptyGroup = action.payload.removeEmptyGroup === undefined ? false : action.payload.removeEmptyGroup;
      removeTaskFromPlanUtil(state, action, removeEmptyGroup);
    },
    reorderTaskGroup: (state, action: PayloadAction<{ oldIndex: number; newIndex: number; newOrder: number }>) => {
      const { oldIndex, newIndex, newOrder } = action.payload;
      if (oldIndex === newIndex) {
        return;
      }
      const group = state.taskGroups.splice(oldIndex, 1)[0];
      group.order = newOrder;
      state.taskGroups.splice(newIndex, 0, group);
    },
    setTimerState: (state, action: PayloadAction<{ timerState: TimerState }>) => {
      setTimerStateUtil(state, action.payload.timerState);
    },
    timerTick: (state, action: PayloadAction<{ dateISOString: string }>) => {
      const oldDateOfLastTick = new Date(state.dateOfLastTickISOString);
      const newDateOfLastTick = new Date(action.payload.dateISOString);

      const dateDiff = (newDateOfLastTick.getTime() - oldDateOfLastTick.getTime()) / 1000;
      state.dateOfLastTickISOString = action.payload.dateISOString;

      if (state.timerState === TimerState.Running) {
        if (state.secondsLeftOnTimer > 0) {
          if (dateDiff < state.secondsLeftOnTimer) {
            state.secondsLeftOnTimer = state.secondsLeftOnTimer - dateDiff;
          } else {
            state.secondsLeftOnTimer = 0;
          }
        } else {
          state.timerState = TimerState.Stopped;
          state.secondsLeftOnTimer = timeForTimerType(state.timerType);
        }
      }
    },
    timerEnd: (state, action: PayloadAction<{ dateISOString: string }>) => {
      const { dateISOString } = action.payload;
      state.dateOfLastTickISOString = dateISOString;

      if (state.timerState === TimerState.Running) {
        state.secondsLeftOnTimer = 0.1;
      } else if (state.timerState === TimerState.Paused) {
        state.secondsLeftOnTimer = 0.1;
        state.timerState = TimerState.Running;
      }
    },
    setTimerType: (state, action: PayloadAction<{ timerType: TimerType }>) => {
      setTimerStateUtil(state, TimerState.Stopped);
      state.timerType = action.payload.timerType;
      state.secondsLeftOnTimer = timeForTimerType(state.timerType);
    },
    setDefaultRoleId: (state, action: PayloadAction<{ roleId: string }>) => {
      state.defaultRoleId = action.payload.roleId;
    },
    setTimerRoleId: (state, action: PayloadAction<{ roleId: string }>) => {
      state.timerRoleId = action.payload.roleId;
    },
    setRoleViewCompleted: (state, action: PayloadAction<{ roleViewCompleted: string }>) => {
      state.roleViewCompleted = action.payload.roleViewCompleted;
    },
    toggleRoleIdSelected: (state, action: PayloadAction<{ roleId: string }>) => {
      localToggleSelectedRole(state, action.payload.roleId);
    },
    setAddTaskInFlight: (state, action: PayloadAction<{ addTaskInFlight: boolean }>) => {
      state.addTaskInFlight = action.payload.addTaskInFlight;
    },
    setEditingInProgress: (state, action: PayloadAction<{ editingInProgress: boolean }>) => {
      state.editingInProgress = action.payload.editingInProgress;
    },
    setAutoAddModeGroup: (state, action: PayloadAction<{ autoAddModeGroup: string }>) => {
      state.autoAddModeGroup = action.payload.autoAddModeGroup;
    },
    setIsEditingTaskId: (state, action: PayloadAction<{ taskId: string; isEditing: boolean }>) => {
      if (action.payload.isEditing) {
        state.isEditingTaskId = action.payload.taskId;
      } else {
        state.isEditingTaskId = "";
      }
    },
    addTimeCardToPlan: (
      state,
      action: PayloadAction<{
        taskGroupId: string;
        taskGroupOrder: number;
        name: string;
        roleId: string;
        roleIdentityId: string | null;
        isGrayTime: boolean;
      }>
    ) => {
      const { taskGroupId, taskGroupOrder, name, isGrayTime } = action.payload;
      const taskGroup = {
        id: taskGroupId,
        name,
        roleId: action.payload.roleId,
        roleIdentityId: action.payload.roleIdentityId === null ? undefined : action.payload.roleIdentityId,
        timeBlockCount: 0,
        completedTimeBlockCount: 0,
        order: taskGroupOrder,
        collapsed: false,
        isGrayTime: isGrayTime,
        tasks: [],
      };
      // Put the taskgroup in the list in the right order
      const groupIndex = state.taskGroups.findIndex((group) => group.order > taskGroupOrder);
      if (groupIndex >= 0) {
        state.taskGroups.splice(groupIndex, 0, taskGroup);
      } else {
        state.taskGroups.push(taskGroup);
      }
    },
    deleteTaskGroupFromPlan: (state, action: PayloadAction<{ taskGroupId: string }>) => {
      const groupIndex = state.taskGroups.findIndex((group) => group.id === action.payload.taskGroupId);
      if (groupIndex < 0) {
        return;
      }
      const group = state.taskGroups[groupIndex];
      const role = state.roles[group.roleId];
      if (!role) {
        return;
      }

      if (tryChangeMeeting(state, role, undefined, group.startTimeBlockNumber, 0, group.timeBlockCount)) {
        state.taskGroups.splice(groupIndex, 1);
      }
    },
    addRoutineToPlan: (
      state,
      action: PayloadAction<{
        routine: RoutinePlanTaskGroup;
        taskGroupId: string;
        taskGroupOrder: number;
        taskIds: string[];
      }>
    ) => {
      const { routine, taskGroupId, taskGroupOrder, taskIds } = action.payload;
      const taskGroup = {
        id: taskGroupId,
        name: routine.name,
        roleId: routine.roleId,
        timeBlockCount: routine.timeBlockCount,
        completedTimeBlockCount: 0,
        order: taskGroupOrder,
        collapsed: false,
        isGrayTime: routine.isGrayTime,
        tasks: routine.tasks.map((task, index) => ({
          id: taskIds[index],
          name: task.name,
          order: task.order,
          completed: false,
          roleId: routine.roleId,
        })),
        routineId: routine.id,
        projectId: routine.projectId,
        roleIdentityId: routine.roleIdentityId,
      };
      // Put the taskgroup in the list in the right order
      const groupIndex = state.taskGroups.findIndex((group) => group.order > taskGroupOrder);
      if (groupIndex >= 0) {
        state.taskGroups.splice(groupIndex, 0, taskGroup);
      } else {
        state.taskGroups.push(taskGroup);
      }
    },
    connectTaskGroupToRoutine: (state, action: PayloadAction<{ taskGroupId: string; routineId: string }>) => {
      const { taskGroupId, routineId } = action.payload;
      const taskGroup = state.taskGroups.find((group) => group.id === taskGroupId);
      if (taskGroup) {
        taskGroup.routineId = routineId;
      }
    },
    removeIncompleteTasksOfTaskGroup: (state, action: PayloadAction<{ taskGroupId: string }>) => {
      const { taskGroupId } = action.payload;
      const taskGroup = state.taskGroups.find((group) => group.id === taskGroupId);
      if (taskGroup) {
        const completeTasks = taskGroup.tasks.filter((task) => task.completed);
        taskGroup.tasks = completeTasks;
      }
    },
    addNewTaskToGroup: (state, action: PayloadAction<{ taskGroupId: string; taskId: string; name: string; order: number }>) => {
      const { taskGroupId, taskId, name, order } = action.payload;
      const taskGroup = state.taskGroups.find((group) => group.id === taskGroupId);
      if (taskGroup) {
        taskGroup.tasks.push({
          id: taskId,
          name,
          order,
          completed: false,
          roleId: taskGroup.roleId,
        });
      }
    },

    setSelectedTaskGroup: (state, action: PayloadAction<{ taskGroupId: string | undefined }>) => {
      state.selectedTaskGroupId = action.payload.taskGroupId;
    },

    addTaskGroupWithTasksToPlan: (state, action: PayloadAction<{ taskGroup: PlanTaskGroup }>) => {
      const taskGroup = action.payload.taskGroup;
      const newTaskGroup = {
        id: taskGroup.id,
        name: taskGroup.name,
        roleId: taskGroup.roleId,
        timeBlockCount: taskGroup.timeBlockCount,
        completedTimeBlockCount: taskGroup.completedTimeBlockCount,
        startTimeBlockNumber: taskGroup.startTimeBlockNumber,
        order: taskGroup.order,
        collapsed: taskGroup.collapsed,
        isGrayTime: taskGroup.isGrayTime,
        routineId: taskGroup.routineId,
        projectId: taskGroup.projectId,
        roleIdentityId: taskGroup.roleIdentityId,
        tasks: taskGroup.tasks.map((task) => {
          return {
            id: task.id,
            order: task.order,
            name: task.name,
            completed: task.completed,
            roleId: taskGroup.roleId,
          };
        }),
      };
      // Put the taskgroup in the list in the right order
      const groupIndex = state.taskGroups.findIndex((group) => group.order > newTaskGroup.order);
      if (groupIndex >= 0) {
        state.taskGroups.splice(groupIndex, 0, newTaskGroup);
      } else {
        state.taskGroups.push(newTaskGroup);
      }
    },
  },
});

export const {
  clearTodayPageState,
  initializeTodayPage,
  setDailyPlanId,
  setStartTimeBlockNumber,
  setEndTimeBlockNumber,
  setTimeBlockNumbers,
  setPlanBlock,
  setTaskGroupRole,
  setTaskGroupTimeBlockCount,
  setTaskGroupCompletedTimeBlockCount,
  setTaskGroupStartTimeBlockNumber,
  setTaskGroupProject,
  setTaskGroupRoleIdentity,
  setTaskGroupCollapsed,
  setTaskGroupGrayTime,
  setTaskGroupName,
  setAllTaskGroupsCollapsed,
  addNewProject,
  removeProject,
  updateProject,
  updateProjectScoreboard,
  setTaskCompleted,
  moveTaskToGroup,
  addTaskToPlan,
  addNewTaskToPlan,
  deletePlannedTask,
  unplanTask,
  setPlannedTaskName,
  reorderTaskGroup,
  timerTick,
  timerEnd,
  setTimerState,
  setTimerType,
  setDefaultRoleId,
  setTimerRoleId,
  setRoleViewCompleted,
  toggleRoleIdSelected,
  setAddTaskInFlight,
  setEditingInProgress,
  setAutoAddModeGroup,
  setIsEditingTaskId,
  addTimeCardToPlan,
  deleteTaskGroupFromPlan,
  addRoutineToPlan,
  connectTaskGroupToRoutine,
  removeIncompleteTasksOfTaskGroup,
  addNewTaskToGroup,
  setSelectedTaskGroup,
  addTaskGroupWithTasksToPlan,
} = todayPageSlice.actions;

export const selectTodayPageState = (state: RootState) => state.todayPage as TodayPageState;

export default todayPageSlice.reducer;
