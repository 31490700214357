import React, { useEffect, useRef } from "react";
import { ButtonBase, ButtonBaseActions, Tooltip, Typography, styled, useMediaQuery } from "@mui/material";
import { SECONDS_IN_A_TIMEBLOCK, formatSecondsTimeSimple } from "frontend-shared/util/dateUtils";
import { TimeBlockMenu } from "./timeBlockMenu";
import {
  RoleBlockType,
  PlanRole,
  PlannedBlockType,
  createRoleBlockType,
  ROLE_BLOCK_BREAK,
  isMeeting,
  isAutoMeeting,
} from "frontend-shared/util/modelTypes";

export interface GuideRailTimeAPIBlockSetters {
  setBlockType: (blockNumber: number, blockType: RoleBlockType) => void;
}

interface Props extends GuideRailTimeAPIBlockSetters {
  startTimeBlockNumber: number;
  blockSize: number;
  blockIndex: number;
  rolesList: PlanRole[];
  role?: PlanRole;
  plannedBlockType?: PlannedBlockType;
  currentBlock: number;
  setCurrentBlock: (blockNumber: number) => void;
}

const GuideRailTimeBlockButton = styled(ButtonBase)(({ theme }) => ({
  border: "1px solid black",
  "&.Mui-focusVisible": {
    border: "1px solid red",
  },
  position: "relative",
}));

export const GuideRailTimeBlock: React.FC<Props> = ({
  startTimeBlockNumber,
  blockSize,
  blockIndex,
  rolesList,
  role,
  plannedBlockType,
  setBlockType,
  currentBlock,
  setCurrentBlock,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const isWide = useMediaQuery("(min-width: 1000px)");

  const buttonRef = useRef<HTMLButtonElement>(null);
  const actionRef = useRef<ButtonBaseActions>(null);
  const blockTypesForMenu: RoleBlockType[] = [
    ...rolesList.flatMap((role) => [
      createRoleBlockType(role, PlannedBlockType.STRATEGIC),
      createRoleBlockType(role, PlannedBlockType.MEETING),
    ]),
    ROLE_BLOCK_BREAK,
  ];
  useEffect(() => {
    if (!buttonRef?.current) {
      return;
    }
    const currentButton = buttonRef.current;
    function globalHandleKeyDown(e: KeyboardEvent) {
      if (e.altKey) {
        switch (e.code) {
          case "KeyG":
          case "ArrowRight":
          case "ArrowLeft": {
            try {
              (document.activeElement as HTMLElement)?.blur();
            } catch (_) {}
            const el = document.getElementById(`time-block-${currentBlock}`);
            if (el) {
              el.focus();
            } else {
              currentButton.focus();
            }
            break;
          }
        }
      }
    }

    function buttonFocus(e: FocusEvent) {
      if (e.target === currentButton) {
        actionRef?.current?.focusVisible();
        setTooltipOpen(true);
        if (tooltipTimeout) {
          clearTimeout(tooltipTimeout);
        }
        setCurrentBlock(blockIndex);
      }
    }
    function buttonFocusOut(e: FocusEvent) {
      if (e.target === currentButton) {
        setTooltipOpen(false);
        if (tooltipTimeout) {
          clearTimeout(tooltipTimeout);
        }
      }
    }
    let tooltipTimeout: NodeJS.Timeout | undefined;
    function buttonMouseEnter(e: MouseEvent) {
      if (e.target === currentButton && !tooltipOpen) {
        tooltipTimeout = setTimeout(() => {
          if (!tooltipOpen) {
            setTooltipOpen(true);
          }
        }, 1000);
      }
    }
    function buttonMouseLeave(e: MouseEvent) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    }

    function buttonHandleKeyUp(e: KeyboardEvent) {
      switch (e.key) {
        case "ArrowLeft":
          if (blockIndex > 0) {
            const el = document.getElementById(`time-block-${blockIndex - 1}`);
            if (el) {
              el.focus();
            }
          }
          break;
        case "ArrowRight":
          const el = document.getElementById(`time-block-${blockIndex + 1}`);
          if (el) {
            el.focus();
          }
          break;
      }
      if (e.key >= "1" && e.key <= "9") {
        if (isAutoMeeting(plannedBlockType)) {
          return;
        }

        const keyNum = parseInt(e.key);
        if (keyNum <= blockTypesForMenu.length) {
          setBlockType(startTimeBlockNumber + blockIndex, blockTypesForMenu[keyNum - 1]);
        }
      }
    }
    if (blockIndex === 0) {
      document.documentElement.addEventListener("keydown", globalHandleKeyDown);
    }
    currentButton.addEventListener("keyup", buttonHandleKeyUp);
    currentButton.addEventListener("focusin", buttonFocus);
    currentButton.addEventListener("focusout", buttonFocusOut);
    currentButton.addEventListener("mouseenter", buttonMouseEnter);
    currentButton.addEventListener("mouseleave", buttonMouseLeave);

    return () => {
      document.documentElement.removeEventListener("keydown", globalHandleKeyDown);
      currentButton.removeEventListener("keyup", buttonHandleKeyUp);
      currentButton.removeEventListener("focusin", buttonFocus);
      currentButton.removeEventListener("focusout", buttonFocusOut);
      currentButton.removeEventListener("mouseenter", buttonMouseEnter);
      currentButton.removeEventListener("mouseleave", buttonMouseLeave);
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBlock,
    setCurrentBlock,
    tooltipOpen,
    setTooltipOpen,
    actionRef,
    buttonRef,
    blockIndex,
    startTimeBlockNumber,
    setBlockType,
  ]);

  const blockMargin = 1;
  function onCloseMenu() {
    setMenuOpen(false);
    setAnchorEl(null);
  }

  const block = role && plannedBlockType ? createRoleBlockType(role, plannedBlockType) : ROLE_BLOCK_BREAK;
  const blockSizeAdjusted = isWide ? blockSize : blockSize * 0.75;

  return (
    <React.Fragment>
      <Tooltip open={tooltipOpen} title={block.name} arrow placement="bottom">
        <GuideRailTimeBlockButton
          id={`time-block-${blockIndex}`}
          sx={{
            m: `${blockMargin}px`,
            height: blockSizeAdjusted,
            width: blockSizeAdjusted,
            backgroundColor: role?.color + (isMeeting(plannedBlockType) ? "88" : "ff"),
          }}
          ref={buttonRef}
          action={actionRef}
          disableRipple
          data-testid="GuideRailTimeBlockButton"
          onClick={(e) => {
            if (isAutoMeeting(plannedBlockType)) {
              return;
            }
            setAnchorEl(e.currentTarget);
            setMenuOpen(true);
            setCurrentBlock(blockIndex);
            buttonRef?.current?.focus();
          }}
        >
          {(startTimeBlockNumber + blockIndex) % 2 === 0 ? (
            <Typography
              fontSize="small"
              sx={{ top: "-19px", left: `-1px`, width: 2 * blockSizeAdjusted }}
              position="absolute"
              align="left"
            >
              {formatSecondsTimeSimple((startTimeBlockNumber + blockIndex) * SECONDS_IN_A_TIMEBLOCK).split(" ")[0]}
            </Typography>
          ) : (
            <></>
          )}
        </GuideRailTimeBlockButton>
      </Tooltip>
      <TimeBlockMenu
        open={menuOpen}
        anchorEl={anchorEl}
        role={role}
        blockTypes={blockTypesForMenu}
        plannedBlockType={plannedBlockType}
        onClose={() => {
          onCloseMenu();
        }}
        rolesList={rolesList}
        blockNumber={startTimeBlockNumber + blockIndex}
        setSelectedBlockType={setBlockType}
      />
    </React.Fragment>
  );
};
