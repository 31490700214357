import React from "react";
import { Button, CircularProgress, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { formatSecondsAsTimeCompact } from "frontend-shared/util/dateUtils";
import { TimerState } from "frontend-shared/util/timerState";
import { TimerType, formatTimerType, parseTimerType } from "frontend-shared/util/timerType";
import { PlanRole } from "frontend-shared/util/modelTypes";
import { colorForRole } from "frontend-shared/store/todayPageStateUtils";
import { Dictionary } from "@reduxjs/toolkit";
import { RoleMenu } from "./roleMenu";

interface Props {
  compact: boolean;
  secondsLeftOnTimer: number;
  totalTimeForTimer: number;
  timerState: TimerState;
  timerType: TimerType;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  roleId: string;
  setRoleId: (roleId: string) => void;
  setTimerType: (timerType: TimerType) => void;
  startTimer: () => void;
  pauseTimer: () => void;
  resetTimer: () => void;
  endTimer: () => void;
}

export const DashboardTimer: React.FC<Props> = ({
  compact,
  secondsLeftOnTimer,
  totalTimeForTimer,
  timerState,
  timerType,
  roles,
  rolesList,
  roleId,
  setRoleId,
  setTimerType,
  startTimer,
  pauseTimer,
  resetTimer,
  endTimer,
}) => {
  const [roleMenuOpen, setRoleMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const sizeMultiplier = compact ? 0.75 : 1;

  return (
    <Stack>
      <Stack direction="row" sx={{ my: "auto" }}>
        <Stack direction="row">
          <Stack direction="row">
            <CircularProgress
              variant="determinate"
              size={100 * sizeMultiplier}
              value={100}
              sx={{
                my: "auto",
                height: `${400 * sizeMultiplier}px`,
                zIndex: 3,
                transformStyle: "preserve-3d",
                color: "#ccc",
              }}
            />
            <CircularProgress
              variant="determinate"
              size={100 * sizeMultiplier}
              value={100 - (100 * secondsLeftOnTimer) / totalTimeForTimer}
              sx={{
                "&.MuiCircularProgress-colorPrimary": { color: colorForRole(roles, roleId) },
                my: "auto",
                height: `${400 * sizeMultiplier}px`,
                zIndex: 3,
                ml: `${-100 * sizeMultiplier}px`,
                transformStyle: "preserve-3d",
              }}
            />
            {timerState === TimerState.Running ? (
              <PauseIcon
                onClick={() => {
                  pauseTimer();
                }}
                sx={{
                  zIndex: 10,
                  ml: `${-85 * sizeMultiplier}px`,
                  height: `${75 * sizeMultiplier}px`,
                  width: `${75 * sizeMultiplier}px`,
                  my: "auto",
                  color: colorForRole(roles, roleId),
                }}
              />
            ) : (
              <PlayArrowIcon
                onClick={() => {
                  startTimer();
                }}
                sx={{
                  zIndex: 10,
                  ml: `${-85 * sizeMultiplier}px`,
                  height: `${75 * sizeMultiplier}px`,
                  width: `${75 * sizeMultiplier}px`,
                  my: "auto",
                  color: colorForRole(roles, roleId),
                }}
              />
            )}
          </Stack>
          <Box sx={{ width: 10 * sizeMultiplier, borderRight: "2px solid", mx: 2 }} />
        </Stack>

        {compact ? (
          <Typography sx={{ fontSize: "20px", m: "auto" }}>{formatSecondsAsTimeCompact(secondsLeftOnTimer)}</Typography>
        ) : (
          <Stack width="100%">
            <Typography sx={{ fontSize: "40px", m: "auto" }}>
              {formatSecondsAsTimeCompact(secondsLeftOnTimer)}
            </Typography>
            <Stack direction="row">
              <Button onClick={resetTimer}>Reset</Button>
              <Button onClick={endTimer}>End</Button>
            </Stack>
          </Stack>
        )}
      </Stack>
      {compact ? (
        <></>
      ) : (
        <>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                sx={{ backgroundColor: colorForRole(roles, roleId), width: 20, height: 20, m: 1 }}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setRoleMenuOpen(true);
                }}
              />
              <Typography>{roles[roleId]?.name}</Typography>
              <Select
                disabled={timerState !== TimerState.Stopped}
                value={formatTimerType(timerType)}
                onChange={(e) => setTimerType(parseTimerType(e.target.value))}
                sx={{ flexGrow: 1 }}
              >
                <MenuItem value={formatTimerType(TimerType.Work)}>Focus Timer</MenuItem>
                <MenuItem value={formatTimerType(TimerType.ShortBreak)}>Short Break</MenuItem>
                <MenuItem value={formatTimerType(TimerType.LongBreak)}>Long Break</MenuItem>
              </Select>
            </Stack>
          </FormControl>
          <RoleMenu
            open={roleMenuOpen}
            anchorEl={anchorEl}
            onClose={() => setRoleMenuOpen(false)}
            roles={rolesList}
            selectedRoleIndex={rolesList.findIndex((role) => role.id === roleId)}
            setSelectedRole={(roleIndex) => {
              setRoleId(rolesList[roleIndex].id);
            }}
          ></RoleMenu>
        </>
      )}
    </Stack>
  );
};
