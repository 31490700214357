import { AnyAction } from "@reduxjs/toolkit";
import { SuccessResponse } from "frontend-shared/generated/graphql";
import { ApolloClient } from "@apollo/client";
import { AppDispatch } from "frontend-shared/store/store";
import { showSnackbarError } from "frontend-shared/store/appSnackbarSlice";

const undoWith = (dispatch: AppDispatch, err: string, undo: AnyAction | AnyAction[]) => {
  if (Array.isArray(undo)) {
    undo.forEach((action) => dispatch(action));
  } else {
    dispatch(undo);
  }
  dispatch(showSnackbarError(err));
};

export const optimisticUpdateAndServerRequestWithUndo = <VARS>({
  client,
  dispatch,
  onStart,
  onEnd,
  optimisticUpdate,
  request,
  variables,
  undo,
}: {
  client: ApolloClient<object>;
  dispatch: AppDispatch;
  onStart?: () => void;
  onEnd?: () => void;
  optimisticUpdate: AnyAction | AnyAction[];
  request: (
    client: ApolloClient<object>,
    variables: VARS,
    success: () => void,
    err: (err: string) => void,
  ) => Promise<SuccessResponse>;
  variables: VARS;
  undo: AnyAction | AnyAction[];
}) => {
  onStart?.();

  if (Array.isArray(optimisticUpdate)) {
    optimisticUpdate.forEach((action) => dispatch(action));
  } else {
    dispatch(optimisticUpdate);
  }

  request(
    client,
    variables,
    () => {
      onEnd?.();
    },
    (err) => {
      undoWith(dispatch, err, undo);
      onEnd?.();
    },
  );
};
