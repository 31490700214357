import React from "react";
import { PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { TimeBlockButtons } from "./timeBlockButtons";
import { timeBlockIsPlannedButUnused } from "../../pages/userApp/today/utils/todayPageUtils";
import { calculateTimeBlocksToRender } from "frontend-shared/util/timeCardUtils";

interface Props {
  taskGroup: PlanTaskGroup;
  onTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  onCompletedTimeBlockClick?: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  colorForRole: (roleId: string) => string;
}

export const PlanTimeBlockButtons: React.FC<Props> = ({ taskGroup, onTimeBlockClick, onCompletedTimeBlockClick, colorForRole }) => {
  const timeBlocksToRender = calculateTimeBlocksToRender(5, taskGroup.timeBlockCount, taskGroup.completedTimeBlockCount);

  return (
    <TimeBlockButtons
      timeBlockCount={taskGroup.timeBlockCount}
      isGray={taskGroup.isGrayTime}
      completedTimeBlockCount={taskGroup.completedTimeBlockCount}
      timeBlocksToRender={timeBlocksToRender}
      timeBlockOpacity={(timeBlockIndex: number) => {
        return timeBlockIsPlannedButUnused(taskGroup, timeBlockIndex) ? 0.5 : 1;
      }}
      onTimeBlockClick={(timeBlockIndex: number) => {
        onTimeBlockClick(taskGroup, timeBlockIndex);
      }}
      onCompletedTimeBlockClick={
        onCompletedTimeBlockClick
          ? (timeBlockIndex: number) => {
              onCompletedTimeBlockClick(taskGroup, timeBlockIndex);
            }
          : undefined
      }
      colorForRole={() => {
        return colorForRole(taskGroup.roleId);
      }}
    />
  );
};
