import React from "react";

import { ApolloClient, ApolloConsumer } from "@apollo/client";

import { selectTodayPageState } from "frontend-shared/store/todayPageSlice";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { colorForRole, generateRolesList } from "frontend-shared/store/todayPageStateUtils";
import { RoleFilter } from "../../pages/userApp/today/roleFilter";
import { Button, Chip, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { selectRoutinesState, toggleRoutinesFilterRoleIdSelected } from "frontend-shared/store/routinesSlice";
import { RoutineTimeBlockButtons } from "./routineTimeBlockButtons";
import { PaperWithTitle } from "../paperWithTitle";
import { apiArchiveRoutine, apiPlanRoutine, apiSetRoutineDaysOfTheWeek } from "frontend-shared/api/today.api.routines";
import { textColorForBackgroundColor } from "frontend-shared/util/colorUtils";
import { RoutinePlanTaskGroup } from "frontend-shared/util/modelTypes";
import { selectBacklogState } from "frontend-shared/store/backlogSlice";
import { TodayOptimisticAPIContext } from "frontend-shared/api/today.api.plan";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { RoutineDaysOfTheWeekButtons } from "./routineDaysOfTheWeekButtons";
import { selectIdentitiesState } from "frontend-shared/store/identitiesSlice";

interface Props {}

export const RoutinesPanel: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  // Today page state that is used by the backlog drawer
  const todayPageState = useAppSelector(selectTodayPageState);
  const routinesState = useAppSelector(selectRoutinesState);
  const backlogState = useAppSelector(selectBacklogState);
  const settingsState = useAppSelector(selectSettingsState);
  const identitiesState = useAppSelector(selectIdentitiesState);
  const makeAPIContext = (client: ApolloClient<object>): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, identitiesState, dispatch };
  };

  const rolesList = generateRolesList(routinesState.roles);

  if (!todayPageState.initialized || !routinesState.initialized) {
    return <></>;
  }

  const routineFilter = (routine: RoutinePlanTaskGroup) => routinesState.selectedRoles[routine.roleId] !== undefined && !routine.archived;

  const numberOfRoutinesInFilter = routinesState.routines.filter(routineFilter).length;

  return (
    <Stack>
      <Stack data-testid="Routines" sx={{ py: 1, top: 0, position: "sticky", backgroundColor: "#eee", zIndex: 10 }}>
        <ApolloConsumer>
          {(client) => (
            <Stack spacing={1} sx={{ mx: 2 }}>
              <RoleFilter
                rolesList={rolesList}
                roles={routinesState.roles}
                selectedRoles={routinesState.selectedRoles}
                toggleSelectRoleId={(roleId: string) => {
                  dispatch(toggleRoutinesFilterRoleIdSelected({ roleId }));
                }}
              />
            </Stack>
          )}
        </ApolloConsumer>
      </Stack>

      <Stack direction="row" flexWrap="wrap">
        <ApolloConsumer>
          {(client) => (
            <>
              {routinesState.routines.length > 0 ? (
                numberOfRoutinesInFilter === 0 ? (
                  <Typography>
                    <em>No routines are in the filter.</em>
                  </Typography>
                ) : (
                  routinesState.routines.filter(routineFilter).map((routine) => {
                    const project = routine.projectId ? todayPageState.projects[routine.projectId] : undefined;
                    return (
                      <Stack key={routine.id} sx={{ width: "220px", m: 2 }}>
                        <PaperWithTitle title={routine.name}>
                          <Stack spacing={1}>
                            {project && (
                              <Chip
                                sx={{
                                  "&.MuiChip-filled": {
                                    backgroundColor: project.color,
                                    color: textColorForBackgroundColor(project.color),
                                    alignSelf: "flex-start",
                                  },
                                }}
                                size="small"
                                label={project.name}
                              />
                            )}
                            <RoutineDaysOfTheWeekButtons
                              routine={routine}
                              onClick={function (routine: RoutinePlanTaskGroup, daysOfTheWeekIndex: number): void {
                                var daysOfTheWeek = routine.daysOfTheWeek;
                                daysOfTheWeek ^= 1 << daysOfTheWeekIndex;
                                apiSetRoutineDaysOfTheWeek(makeAPIContext(client), routine, daysOfTheWeek);
                              }}
                              colorForRole={(roleId: string) => colorForRole(routinesState.roles, roleId)}
                            />
                            <RoutineTimeBlockButtons
                              small
                              routine={routine}
                              onTimeBlockClick={(routine, timeBlockIndex: number) => {}}
                              colorForRole={(roleId: string) => colorForRole(routinesState.roles, roleId)}
                            />
                            <List sx={{ listStyleType: "disc", p: 0 }}>
                              {routine.tasks.map((task) => (
                                <ListItem key={task.id} sx={{ p: 0 }}>
                                  <ListItemText sx={{ p: 0, mx: 2, display: "list-item" }} primary={task.name} />
                                </ListItem>
                              ))}
                            </List>
                            <Stack direction="row" justifyContent="center">
                              <Button
                                onClick={() => {
                                  apiPlanRoutine(makeAPIContext(client), routine);
                                }}
                              >
                                Plan
                              </Button>
                              <Button
                                onClick={() => {
                                  apiArchiveRoutine(makeAPIContext(client), routine);
                                }}
                              >
                                Archive
                              </Button>
                            </Stack>
                          </Stack>
                        </PaperWithTitle>
                      </Stack>
                    );
                  })
                )
              ) : (
                <Typography>
                  <em>No routines.</em>
                </Typography>
              )}
            </>
          )}
        </ApolloConsumer>
      </Stack>
    </Stack>
  );
};
