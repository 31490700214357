import React from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { PromptForDateDialog } from "../../../components/dialogs/promptForDateDialog";
import { formatDateLong } from "frontend-shared/util/dateUtils";

interface Props {
  date: Date;
  setDate: (date: Date) => void;
}

export const DateNavigation: React.FC<Props> = ({ date, setDate }) => {
  const [dateNavigationIsOpen, setDateNavigationIsOpen] = React.useState(false);

  const dateHeader = formatDateLong(date);

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
      <Tooltip title="Go to the previous day">
        <IconButton
          onClick={() => {
            const newDate = new Date(date);
            newDate.setDate(date.getDate() - 1);
            setDate(newDate);
          }}
          data-testid="PreviousDayButton"
        >
          <ArrowLeftIcon />
        </IconButton>
      </Tooltip>

      <Typography variant="h6">{dateHeader}</Typography>
      <Tooltip title="Go to date">
        <IconButton
          onClick={() => {
            setDateNavigationIsOpen(true);
          }}
        >
          <CalendarMonthIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Go to today">
        <Button
          sx={{ color: "#333", borderColor: "#333", ":hover": { backgroundColor: "#ddd", borderColor: "#333" } }}
          variant="outlined"
          onClick={() => {
            setDate(new Date());
          }}
        >
          Today
        </Button>
      </Tooltip>
      <Tooltip title="Go to the next day">
        <IconButton
          onClick={() => {
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);
            setDate(newDate);
          }}
          data-testid="NextDayButton"
        >
          <ArrowRightIcon />
        </IconButton>
      </Tooltip>
      <PromptForDateDialog
        title="Go to a plan on date"
        currentDate={date}
        open={dateNavigationIsOpen}
        onClose={() => {
          setDateNavigationIsOpen(false);
        }}
        buttonLabel="Go"
        onSave={async (date: Date, onSuccess: () => void, onError: (msg: string) => void): Promise<void> => {
          setDate(date);
          onSuccess();
        }}
      />
    </Stack>
  );
};
