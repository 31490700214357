import { TodayPageState } from "../store/todayPageSlice";
import { BacklogState } from "../store/backlogSlice";
import { RoutinesState } from "../store/routinesSlice";

export const localToggleSelectedRole = <T extends TodayPageState | BacklogState | RoutinesState>(
  state: T,
  roleId: string
) => {
  const role = state.roles[roleId];
  if (role) {
    if (state.selectedRoles[roleId]) {
      delete state.selectedRoles[roleId];
    } else {
      state.selectedRoles[roleId] = role;
    }
  }
  const selectedRoleIds = Object.keys(state.selectedRoles);
  if (selectedRoleIds.length > 0 && state.selectedRoles[state.defaultRoleId] === undefined) {
    state.defaultRoleId = selectedRoleIds[0];
  }
};
