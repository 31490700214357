import React from "react";

import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { UnplannedTasks } from "../../pages/userApp/today/unplannedTasks";
import { PlanRole, PlanTask } from "frontend-shared/util/modelTypes";

import { selectTodayPageState, setAddTaskInFlight, setEditingInProgress } from "frontend-shared/store/todayPageSlice";
import { AddUnplannedTask } from "../../pages/userApp/today/addUnplannedTask";

import {
  apiAddUnplannedTask,
  apiDeleteUnplannedTask,
  apiEditUnplannedTaskName,
  apiReorderUnplannedTask,
  apiSetUnplannedTaskRole,
  localSetDefaultRoleIdForBacklogAddTaskToPlan,
} from "frontend-shared/api/today.api.backlog";

import { apiPlanTask } from "frontend-shared/api/today.api.plan.backlog";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { selectBacklogState, toggleBacklogFilterRoleIdSelected } from "frontend-shared/store/backlogSlice";
import { generateRolesList, shouldBlockPlanUIFromState, shouldBlockUnplannedUIFromState } from "frontend-shared/store/todayPageStateUtils";
import { RoleFilter } from "../../pages/userApp/today/roleFilter";
import { Stack, Typography } from "@mui/material";
import { selectRoutinesState } from "frontend-shared/store/routinesSlice";
import { TodayOptimisticAPIContext, localSetDefaultRoleIdForAddTaskToPlan } from "frontend-shared/api/today.api.plan";
import { selectSettingsState } from "frontend-shared/store/settingsSlice";
import { selectIdentitiesState } from "frontend-shared/store/identitiesSlice";

interface Props {}

export const BacklogPanel: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  // Today page state that is used by the backlog drawer
  const todayPageState = useAppSelector(selectTodayPageState);
  const backlogState = useAppSelector(selectBacklogState);
  const routinesState = useAppSelector(selectRoutinesState);
  const settingsState = useAppSelector(selectSettingsState);
  const identitiesState = useAppSelector(selectIdentitiesState);

  const makeAPIContext = (client: ApolloClient<object>): TodayOptimisticAPIContext => {
    return { client, todayPageState, backlogState, routinesState, settingsState, identitiesState, dispatch };
  };

  const rolesList = generateRolesList(backlogState.roles);
  const shouldBlockPlanUI = shouldBlockPlanUIFromState(todayPageState, backlogState);
  const shouldBlockUnplannedUI = shouldBlockUnplannedUIFromState(todayPageState, backlogState);

  if (!todayPageState.initialized || !backlogState.initialized || !settingsState.initialized || !routinesState.initialized) {
    return <></>;
  }

  return (
    <Stack>
      <Stack data-testid="Backlog" sx={{ py: 1, top: 0, position: "sticky", backgroundColor: "#eee", zIndex: 10 }}>
        <ApolloConsumer>
          {(client) => (
            <Stack spacing={1} sx={{ mx: 2 }}>
              <AddUnplannedTask
                addTaskButtonDisabled={backlogState.addTaskInFlight}
                addTask={(name) =>
                  apiAddUnplannedTask(makeAPIContext(client), name, backlogState.defaultRoleId, (addTaskInFlight) =>
                    dispatch(setAddTaskInFlight({ addTaskInFlight })),
                  )
                }
                roles={backlogState.roles}
                rolesList={rolesList}
                defaultRoleForAddTask={backlogState.roles[backlogState.defaultRoleId] ?? rolesList[0]}
                setDefaultRoleForAddTask={(role: PlanRole) => {
                  localSetDefaultRoleIdForBacklogAddTaskToPlan(role.id, dispatch);
                  localSetDefaultRoleIdForAddTaskToPlan(role.id, dispatch);
                }}
              />
              <RoleFilter
                rolesList={rolesList}
                roles={backlogState.roles}
                selectedRoles={backlogState.selectedRoles}
                toggleSelectRoleId={(roleId: string) => {
                  dispatch(toggleBacklogFilterRoleIdSelected({ roleId }));
                }}
              />
            </Stack>
          )}
        </ApolloConsumer>
      </Stack>
      <Stack sx={{ flex: "1 1 auto", mx: 2, my: 2 }}>
        {backlogState.unplannedTasks.length === 0 ? (
          <Typography data-testid="NoUnplannedTasks">
            <em>There are no tasks in the backlog</em>
          </Typography>
        ) : (
          <Stack sx={{ flex: "1 1 auto", borderRadius: 2, backgroundColor: "#fff", p: 1 }}>
            <ApolloConsumer>
              {(client) => (
                <UnplannedTasks
                  shouldBlockUnplannedUI={shouldBlockUnplannedUI}
                  shouldBlockPlanUI={shouldBlockPlanUI}
                  setEditingInProgress={(editingInProgress: boolean) => dispatch(setEditingInProgress({ editingInProgress }))}
                  unplannedTasks={backlogState.unplannedTasks}
                  roles={backlogState.roles}
                  rolesList={rolesList}
                  selectedRoles={backlogState.selectedRoles}
                  planTask={(task) => apiPlanTask(makeAPIContext(client), task)}
                  editTaskName={(task, newName) => apiEditUnplannedTaskName(makeAPIContext(client), task, newName)}
                  deleteTask={(task) => apiDeleteUnplannedTask(makeAPIContext(client), task)}
                  reorderTask={(oldIndex, newIndex) => apiReorderUnplannedTask(makeAPIContext(client), oldIndex, newIndex)}
                  setTaskRole={(task: PlanTask, role: PlanRole) => {
                    apiSetUnplannedTaskRole(makeAPIContext(client), task, role);
                  }}
                />
              )}
            </ApolloConsumer>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
