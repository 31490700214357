import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { RoutinePlanTaskGroup } from "frontend-shared/util/modelTypes";
import { dayName } from "frontend-shared/util/dateUtils";

interface Props {
  planDate: Date;
  showRoutines: boolean;
  routinesToDisplay: RoutinePlanTaskGroup[];
  onClick: (routine: RoutinePlanTaskGroup) => void;
}

export const DailyRoutinesPrompt: React.FC<Props> = ({ planDate, showRoutines, routinesToDisplay, onClick }) => {
  return (
    <>
      {showRoutines && routinesToDisplay.length > 0 && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6">{dayName(planDate)} Routines:</Typography>
          {routinesToDisplay.map((r) => (
            <Button key={r.id} onClick={() => onClick(r)}>
              {r.name}
            </Button>
          ))}
        </Stack>
      )}
    </>
  );
};
