import React from "react";
import { PlanRole } from "frontend-shared/util/modelTypes";
import { Dictionary } from "@reduxjs/toolkit";
import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { colorForRole } from "frontend-shared/store/todayPageStateUtils";

interface Props {
  rolesList: PlanRole[];
  roles: Dictionary<PlanRole>;
  selectedRoles: Dictionary<PlanRole>;
  toggleSelectRoleId: (roleId: string) => void;
}

export const RoleFilter: React.FC<Props> = ({ rolesList, roles, selectedRoles, toggleSelectRoleId }) => {
  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, newRoleIds: string[]) => {
    for (const role of rolesList) {
      if (newRoleIds.includes(role.id) !== (selectedRoles[role.id] !== undefined)) {
        toggleSelectRoleId(role.id);
      }
    }
  };
  return (
    <ToggleButtonGroup
      value={Object.values(selectedRoles).map((role) => role?.id ?? "")}
      onChange={handleFilterChange}
      aria-label="role filter"
      data-testid="RoleFilter"
    >
      {rolesList.map((role) => (
        <ToggleButton key={role.id} data-testid="ToggleButton" value={role.id} aria-label={role.name}>
          <Tooltip title={`${selectedRoles[role.id] !== undefined ? "Hide" : "Show"} ${role.name} Tasks`}>
            <Box
              sx={{
                p: "8px",
                opacity: `${selectedRoles[role.id] !== undefined ? 1 : 0.5}`,
                backgroundColor: colorForRole(roles, role.id),
              }}
            />
          </Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
