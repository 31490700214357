import React from "react";
import { DropResult } from "@hello-pangea/dnd";
import { PlanRole, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { Dictionary } from "@reduxjs/toolkit";
import { TaskGroupsWithRolesInterspersed } from "./taskGroupsWithRolesInterspersed";
import { hasIncompleteTasksOrEmpty, allTasksInSelectedRoles } from "frontend-shared/store/todayPageStateUtils";
import { Stack, Typography } from "@mui/material";
import { TaskGroupLabel } from "./draggableTaskGroup";
import { TaskGroupMenuProps } from "./taskGroupActionMenu";
import { TaskEditProps } from "./plannedTask";
import { TaskGroupHeaderProps } from "./draggableTaskGroupHeader";

interface Props extends TaskGroupMenuProps, TaskEditProps, TaskGroupHeaderProps {
  taskGroups: PlanTaskGroup[];
  dropEnd: (result: DropResult) => void;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  roleViewCompleted: string;
  selectedRoles: Dictionary<PlanRole>;
  editingInProgress: boolean;
  toggleTaskGroupCollapsed: (task: PlanTaskGroup) => void;
  shouldBlockUI: boolean;
  setEditingInProgress: (block: boolean) => void;
  onTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  onCompletedTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  colorForRole: (roleId: string) => string;
  canBeGrouped: (index: number, taskGroup: PlanTaskGroup, inFilter: (taskGroup: PlanTaskGroup) => boolean) => boolean;
  labelsForTaskGroup: (taskGroup: PlanTaskGroup) => TaskGroupLabel[];
  onSelectTaskGroup: (taskGroupId: string) => void;
}

export const TaskGroupsWithRolesSequential: React.FC<Props> = (props: Props) => {
  const { rolesList, selectedRoles, taskGroups, labelsForTaskGroup, roleViewCompleted } = props;

  const taskGroupInRole = (taskGroup: PlanTaskGroup, role: PlanRole) => {
    return allTasksInSelectedRoles(taskGroup, { [role.id]: role });
  };

  const areTaskGroupsShownInRoleAndView = (role: PlanRole) => {
    return taskGroups.some(
      (taskGroup) =>
        taskGroupInRole(taskGroup, role) && (roleViewCompleted !== "Hide" || hasIncompleteTasksOrEmpty(taskGroup)),
    );
  };

  const areMultipleRolesWithGroups =
    Object.values(selectedRoles).reduce((acc, role) => {
      return acc + (role && areTaskGroupsShownInRoleAndView(role) ? 1 : 0);
    }, 0) > 1;

  return (
    <React.Fragment>
      {rolesList
        .filter((role) => selectedRoles[role.id])
        .map((role) => (
          <Stack key={role.id}>
            {areMultipleRolesWithGroups && areTaskGroupsShownInRoleAndView(role) ? (
              <Typography variant="h6">{role.name}</Typography>
            ) : (
              <></>
            )}
            <TaskGroupsWithRolesInterspersed
              key={role.id}
              droppableId={`droppable-${role.id}`}
              {...props}
              inFilter={(taskGroup) => {
                return (
                  taskGroupInRole(taskGroup, role) &&
                  (roleViewCompleted !== "Hide" || hasIncompleteTasksOrEmpty(taskGroup))
                );
              }}
              labelsForTaskGroup={labelsForTaskGroup}
            />
          </Stack>
        ))}
    </React.Fragment>
  );
};
