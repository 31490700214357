import React from "react";
import { AppLayout } from "../../components/layouts/appLayout";
import { PaperWithTitle } from "../../components/paperWithTitle";
import { useAdminStatsPageQuery } from "frontend-shared/generated/graphql";
import { AppLoadingPage } from "../../components/appLoadingPage";
import { AppErrorPage } from "../../components/appErrorPage";
import { Line } from "react-chartjs-2";
import { Stack, Typography } from "@mui/material";
import { formatDateSimple } from "frontend-shared/util/dateUtils";

export const AdminStatsPage: React.FC = () => {
  const { data, loading, error } = useAdminStatsPageQuery({
    variables: {},
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <AppLoadingPage />;
  } else if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  } else if (!data) {
    return <AppLoadingPage />;
  }

  const planLabels = data.adminStats.plans.map((weekStat) => {
    const date = new Date(weekStat.weekEndDateString + "T00:00:00");
    return formatDateSimple(date);
  });
  const planData = data.adminStats.plans.map((weekStat) => weekStat.count);
  const userData = data.adminStats.plans.map((weekStat) => {
    const userCountIndex = data.adminStats.users.findLastIndex((u) => {
      const pd = new Date(weekStat.weekEndDateString + "T00:00:00");
      const ud = new Date(u.weekEndDateString + "T00:00:00");
      return pd >= ud;
    });
    return userCountIndex === -1 ? 0 : data.adminStats.users[userCountIndex].count;
  });
  const activeUserData = data.adminStats.activeUsers.map((weekStat) => weekStat.count);

  return (
    <AppLayout chosen="Stats">
      <PaperWithTitle title="Site Statistics">
        <Stack direction="row" flexWrap="wrap">
          <Stack sx={{ width: "400px", m: 2 }}>
            <Typography variant="h6">Plans per week</Typography>
            <Line
              options={{
                animation: {
                  duration: 0,
                },
                responsive: true,
                plugins: { legend: { display: false } },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                  },
                },
              }}
              data={{
                labels: planLabels,
                datasets: [{ data: planData, borderColor: "blue" }],
              }}
            />
          </Stack>
          <Stack sx={{ width: "400px", m: 2 }}>
            <Typography variant="h6">Total Users each week (active in red)</Typography>
            <Line
              options={{
                animation: {
                  duration: 0,
                },
                responsive: true,
                plugins: { legend: { display: false } },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                  },
                },
              }}
              data={{
                labels: planLabels,
                datasets: [
                  { data: userData, borderColor: "blue" },
                  { data: activeUserData, borderColor: "red" },
                ],
              }}
            />
          </Stack>
        </Stack>
      </PaperWithTitle>
    </AppLayout>
  );
};
