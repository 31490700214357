export const DEFAULT_MAX_TIME_BLOCK_COUNT = 48;
export const DEFAULT_TIME_BLOCK_COUNT = 0;

export interface OrderedObject {
  id: string;
  order: number;
}

export const LIST_ORDER_SPACING = 1000;

export const nextOrder = (objects: OrderedObject[]) => {
  return (objects[objects.length - 1]?.order ?? 0) + 1000;
};

export interface PlanRole extends OrderedObject {
  name: string;
  color: string;
}

export interface EditablePlanTask {
  name: string;
}

export const emptyEditablePlanTask = (): EditablePlanTask => {
  return { name: "" };
};

export interface PlanTask extends EditablePlanTask, OrderedObject {
  completed: boolean;
  roleId: string;
}

export interface EditablePlanTaskGroup {
  // If you add a new field here, make sure to update the recoveryData for some undo actions.
  // When the taskGroup is deleted, the recoveryData is used to recreate it.
  name: string | null;
  roleIdentityId?: string;
}

export const emptyEditablePlanTaskGroup = (): EditablePlanTaskGroup => {
  return { name: "" };
};

export interface PlanTaskGroup extends EditablePlanTaskGroup, OrderedObject {
  // If you add a new field here, make sure to update the recoveryData for some undo actions.
  // When the taskGroup is deleted, the recoveryData is used to recreate it.
  roleId: string;
  timeBlockCount: number;
  completedTimeBlockCount: number;
  startTimeBlockNumber?: number;

  // Also, some fields need to be copied to new task groups when they are created/combined.
  // see moveTaskToGroup
  routineId?: string;
  projectId?: string;
  // From EditablePlanTaskGroup: roleIdentityId?: string; also needs to be copied

  collapsed: boolean;
  isGrayTime: boolean;
  tasks: PlanTask[];
}

export interface PlanProjectDataForScoreboard {
  weekEndDate: string;
  completedTimeBlockCount: number;
}

export interface PlanProject {
  id: string;
  name: string;
  roleId: string;
  color: string;
  showOnScoreboard: boolean;
  startDate: string;
  endDate: string;
  weeklyPaceTimeBlockCount: number;
  weekStartsDayOfWeek: number;
  dataForScoreboard: PlanProjectDataForScoreboard[];
}

export interface EditablePlanRoleIdentity {
  name: string;
  color: string;
  weeklyPaceTimeBlockCount: number;
  order: number;
}

export const emptyEditablePlanRoleIdentity = (): EditablePlanRoleIdentity => {
  return { name: "", color: "", weeklyPaceTimeBlockCount: 0, order: 1000 };
};

export interface PlanRoleIdentity extends EditablePlanRoleIdentity, OrderedObject {
  roleId: string;
}

export const nullPlanProject: PlanProject = {
  id: "",
  name: "",
  roleId: "",
  color: "",
  showOnScoreboard: false,
  startDate: "",
  endDate: "",
  weeklyPaceTimeBlockCount: 0,
  weekStartsDayOfWeek: 0,
  dataForScoreboard: [],
};

export interface RoutinePlanTask extends OrderedObject {
  name: string;
}

export interface RoutinePlanTaskGroup {
  id: string;
  name: string;
  timeBlockCount: number;
  archived: boolean;
  roleId: string;
  projectId?: string;
  roleIdentityId?: string;
  isGrayTime: boolean;
  daysOfTheWeek: number;
  tasks: RoutinePlanTask[];
}

export const calculateOrderAfterReorder = (oldIndex: number, newIndex: number, objects: OrderedObject[]) => {
  if (newIndex < oldIndex) {
    const beforeOrder = objects[newIndex - 1]?.order ?? 0;
    const afterOrder = objects[newIndex]?.order ?? objects[objects.length - 1].order + 2000;
    return (beforeOrder + afterOrder) / 2;
  } else if (newIndex > oldIndex) {
    const beforeOrder = objects[newIndex]?.order ?? 0;
    const afterOrder = objects[newIndex + 1]?.order ?? objects[objects.length - 1].order + 2000;
    return (beforeOrder + afterOrder) / 2;
  }
  return objects[oldIndex].order;
};

/**
 * Represents a planned block type used by the guide rail
 */

/* eslint no-unused-vars: [ "error", { "varsIgnorePattern": "AUTO_MEETING|AUTO_MEETING_GRAY|MEETING|STRATEGIC|GRAY" } ] */
export enum PlannedBlockType {
  MEETING = "Meeting", // Deprecated, but in the database.
  AUTO_MEETING = "Auto-Meeting",
  AUTO_MEETING_GRAY = "Auto-Meeting-Gray",
  STRATEGIC = "Strategic",
  GRAY = "Gray",
}

/**
 * A PlannedBlockType enum matching the string passed in
 * @param s Either "Meeting", "Auto-Meeting", "Strategic", "Auto-Meeting-Gray", "Gray"
 * @returns the associated PlannedBlockType. Throws if there is no match.
 */
export function parsePlannedBlockType(s: string): PlannedBlockType {
  const lower = s.toLowerCase();
  if (lower === "meeting") {
    return PlannedBlockType.MEETING;
  } else if (lower === "auto-meeting") {
    return PlannedBlockType.AUTO_MEETING;
  } else if (lower === "auto-meeting-gray") {
    return PlannedBlockType.AUTO_MEETING_GRAY;
  } else if (lower === "strategic") {
    return PlannedBlockType.STRATEGIC;
  } else if (lower === "gray") {
    return PlannedBlockType.GRAY;
  } else {
    throw new Error("unknown planned block type");
  }
}

export interface RoleBlockType {
  name: string;
  role?: PlanRole;
  blockType?: PlannedBlockType;
}

export function isMeeting(blockType?: PlannedBlockType): boolean {
  return blockType === PlannedBlockType.MEETING || isAutoMeeting(blockType);
}

export function isAutoMeeting(blockType?: PlannedBlockType): boolean {
  return blockType === PlannedBlockType.AUTO_MEETING || blockType === PlannedBlockType.AUTO_MEETING_GRAY;
}

export function createRoleBlockType(role: PlanRole, blockType: PlannedBlockType): RoleBlockType {
  return { name: role.name + " " + (isMeeting(blockType) ? "Meeting" : "Block"), role, blockType };
}

export const ROLE_BLOCK_BREAK = {
  name: "Break",
};
