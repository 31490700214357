import React, { useState } from "react";
import { PlanProject } from "frontend-shared/util/modelTypes";
import { FormDialog } from "./formDialog";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { EditProjectDialog } from "./editProjectDialog";
import { dateAddingDays, formatDateSimple } from "frontend-shared/util/dateUtils";

interface Props {
  projects: PlanProject[];
  canClearProject: boolean;
  roleId: string;
  open: boolean;
  onClose: () => void;

  buttonLabel: string;
  onSave: (project: PlanProject | undefined, onSuccess: () => void, onError: (msg: string) => void) => Promise<void>;
  addProject: (project: PlanProject, onSuccess: () => void) => Promise<void>;
}

export const PromptForProjectDialog: React.FC<Props> = ({
  projects,
  canClearProject,
  roleId,
  open,
  onClose,
  buttonLabel,
  onSave,
  addProject,
}) => {
  const [project, setProject] = useState<PlanProject | undefined>(undefined);
  const [promptForProjectDetailsIsOpen, setPromptForProjectDetailsIsOpen] = useState<boolean>(false);

  if (!open && project) {
    setProject(undefined);
    return;
  }

  const saveIsDisabled = () => {
    return !project;
  };

  const closeDialog = () => {
    onClose();
  };

  const saveAndCloseDialog = async () => {
    await onSave(
      project,
      () => {
        closeDialog();
      },
      (msg) => {
        return msg;
      },
    );
  };

  const clearProjectButton = canClearProject
    ? [
        {
          label: "Clear Project",
          onClick: () => {
            setProject(undefined);
            saveAndCloseDialog();
          },
        },
      ]
    : [];

  const projectsInRole = projects.filter((prj) => prj.roleId === roleId);

  return (
    <FormDialog
      title={"Assign tasks to a project"}
      open={open}
      cancel={closeDialog}
      errorMessage=""
      saveAndClose={saveAndCloseDialog}
      saveIsDisabled={saveIsDisabled}
      saveButtonLabel={buttonLabel}
      extraButtons={[
        {
          label: "Add Project",
          onClick: () => {
            setPromptForProjectDetailsIsOpen(true);
          },
        },
        ...clearProjectButton,
      ]}
    >
      {projectsInRole.length === 0 ? (
        <Typography>
          <em>No projects in this role</em>
        </Typography>
      ) : (
        <List sx={{ minWidth: 400, border: "solid 0.5px gray" }}>
          {projectsInRole.map((prj) => {
            return (
              <ListItemButton
                key={prj.id}
                onClick={(e) => {
                  setProject(prj);
                }}
                selected={prj === project}
              >
                <ListItemText primary={prj.name} />
              </ListItemButton>
            );
          })}
        </List>
      )}
      <EditProjectDialog
        title="Add Project"
        open={promptForProjectDetailsIsOpen}
        saveAndClose={(project: PlanProject) => {
          addProject(project, () => {
            setPromptForProjectDetailsIsOpen(false);
          });
        }}
        cancel={() => {
          setPromptForProjectDetailsIsOpen(false);
        }}
        project={{
          id: "",
          name: "",
          color: "#f0f0f0",
          showOnScoreboard: false,
          startDate: formatDateSimple(new Date()),
          endDate: formatDateSimple(dateAddingDays(new Date(), 35)),
          weeklyPaceTimeBlockCount: 0,
          weekStartsDayOfWeek: 0,
          roleId,
          dataForScoreboard: [],
        }}
      />
    </FormDialog>
  );
};
