import React, { useState } from "react";
import { PaperWithTitle } from "../../components/paperWithTitle";
import { AppLayout } from "../../components/layouts/appLayout";
import { ApolloConsumer } from "@apollo/client";
import { useInvitationsQuery } from "frontend-shared/generated/graphql";
import { AppErrorPage } from "../../components/appErrorPage";
import { AppLoadingPage } from "../../components/appLoadingPage";
import { Stack, Tab } from "@mui/material";
import { requestInviteRequestedSiteUser, requestResendInvitation } from "frontend-shared/generated/graphqlWrappers";
import { useAppDispatch } from "frontend-shared/store/hooks";
import { AppSnackbar } from "../../components/appSnackbar";
import { showSnackbarError, showSnackbarSuccess } from "frontend-shared/store/appSnackbarSlice";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { InvitationUser, InvitationUsersTable } from "./invitations/invitationUsersTable";

export const InvitationsPage: React.FC = () => {
  const [tab, setTab] = useState<"1" | "2">("1");
  const dispatch = useAppDispatch();

  const { data, loading, error, refetch } = useInvitationsQuery({
    variables: {},
    fetchPolicy: "network-only",
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading) {
    return <AppLoadingPage />;
  }
  if (!data) {
    return <AppErrorPage errorMessage="No data received" />;
  }

  return (
    <AppLayout chosen="Invitations">
      <ApolloConsumer>
        {(client) => (
          <PaperWithTitle title="Invitations">
            <TabContext value={tab}>
              <Stack direction="row">
                <TabList
                  onChange={(event, t) => {
                    setTab(t);
                  }}
                >
                  <Tab label="Sent" value="1" />
                  <Tab label="Requested" value="2" />
                </TabList>
              </Stack>
              <TabPanel value="1">
                <InvitationUsersTable
                  data={data.invitedUsers}
                  dataDescription={"invited users"}
                  buttons={[
                    {
                      label: "Resend",
                      onClick: (obj: InvitationUser) => {
                        requestResendInvitation(
                          client,
                          { userId: obj.id },
                          () => {
                            dispatch(showSnackbarSuccess("Invitation resent"));
                          },
                          (err) => {
                            dispatch(showSnackbarError(err));
                          },
                        );
                        return false;
                      },
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel value="2">
                <InvitationUsersTable
                  data={data.inviteRequestedUsers}
                  dataDescription={"users that requested an invitation"}
                  buttons={[
                    {
                      label: "Invite",
                      onClick: (obj: InvitationUser) => {
                        requestInviteRequestedSiteUser(
                          client,
                          { userId: obj.id },
                          () => {
                            dispatch(showSnackbarSuccess("User invited successfully"));
                            refetch();
                          },
                          (err) => {
                            dispatch(showSnackbarError(err));
                          },
                        );
                        return false;
                      },
                    },
                  ]}
                />
              </TabPanel>
            </TabContext>
          </PaperWithTitle>
        )}
      </ApolloConsumer>
      <AppSnackbar />
    </AppLayout>
  );
};
