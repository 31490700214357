import React from "react";
import { RoutinePlanTaskGroup } from "frontend-shared/util/modelTypes";
import { Stack, Typography } from "@mui/material";
import { daysOfTheWeekAbbreviations } from "frontend-shared/util/dateUtils";

interface Props {
  routine: RoutinePlanTaskGroup;
  onClick: (routine: RoutinePlanTaskGroup, daysOfTheWeekIndex: number) => void;
  colorForRole: (roleId: string) => string;
}

export const RoutineDaysOfTheWeekButtons: React.FC<Props> = ({ routine, onClick, colorForRole }) => {
  return (
    <Stack direction="row" spacing="2px">
      {daysOfTheWeekAbbreviations.map((dayAbbreviation, index) => (
        <Typography
          key={index}
          onClick={() => onClick(routine, index)}
          sx={{
            cursor: "pointer",
            px: "5px",
            py: "3px",
            ":hover": { backgroundColor: "#eee" },
            color: routine.daysOfTheWeek & (1 << index) ? colorForRole(routine.roleId) : "#999",
          }}
        >
          {dayAbbreviation}
        </Typography>
      ))}
    </Stack>
  );
};
