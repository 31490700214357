import React from "react";
import { AppLayout } from "../../components/layouts/appLayout";
import { PaperWithTitle } from "../../components/paperWithTitle";
import { Button, Stack, Typography } from "@mui/material";
import { AppSnackbar } from "../../components/appSnackbar";
import { useAppDispatch } from "frontend-shared/store/hooks";
import { requestInviteSiteUser } from "frontend-shared/generated/graphqlWrappers";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import { uuidForId } from "frontend-shared/util/uuidUtils";
import { InvitationDialogFields } from "../../components/dialogs/invitationDialogFields";

export const InviteUserPage: React.FC = () => {
  const [email, setEmail] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [inviteCodeToShow, setInviteCodeToShow] = React.useState("");

  const dispatch = useAppDispatch();

  const onInviteClick = (client: ApolloClient<object>) => {
    const inviteCode = uuidForId();
    requestInviteSiteUser(
      client,
      { email, displayName, inviteCode },
      () => {
        setInviteCodeToShow(inviteCode);
      },
      (err) => {
        dispatch(showSnackbarError(err));
      },
    );
  };

  return (
    <AppLayout chosen="Invite">
      <ApolloConsumer>
        {(client) => (
          <PaperWithTitle title={inviteCodeToShow ? "Invitation Sent" : "Invite User"}>
            {inviteCodeToShow === "" ? (
              <Stack spacing={2} sx={{ width: "400px" }}>
                <InvitationDialogFields
                  email={email}
                  setEmail={setEmail}
                  displayName={displayName}
                  setDisplayName={setDisplayName}
                />
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{ flexGrow: 0 }}
                    onClick={() => {
                      onInviteClick(client);
                    }}
                    disabled={email === "" || displayName === ""}
                  >
                    Invite
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <Stack spacing={2}>
                <Typography>An invitation e-mail has been sent to &lt;{email}&gt;</Typography>
                <Stack direction="row">
                  <Button
                    variant="contained"
                    sx={{ flexGrow: 0 }}
                    onClick={() => {
                      setEmail("");
                      setDisplayName("");
                      setInviteCodeToShow("");
                    }}
                  >
                    Invite Another
                  </Button>
                </Stack>
              </Stack>
            )}
          </PaperWithTitle>
        )}
      </ApolloConsumer>
      <AppSnackbar />
    </AppLayout>
  );
};
