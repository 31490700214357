import { RoutinesState } from "frontend-shared/store/routinesSlice";
import { PlanTaskGroup, RoutinePlanTaskGroup } from "frontend-shared/util/modelTypes";

export const routinesNotOnPlan = (routinesState: RoutinesState, taskGroups: PlanTaskGroup[]): RoutinePlanTaskGroup[] => {
  return routinesState.routines.filter((routine) => !routine.archived && !taskGroups.some((taskGroup) => taskGroup.routineId === routine.id));
};

export const routinesForDay = (routinesState: RoutinesState, taskGroups: PlanTaskGroup[], date: Date): RoutinePlanTaskGroup[] => {
  return routinesNotOnPlan(routinesState, taskGroups).filter((routine) => (routine.daysOfTheWeek & (1 << date.getDay())) !== 0);
};
