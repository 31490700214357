import { IconButton, Stack, TextField } from "@mui/material";
import React from "react";
import { ColorPickerPopover } from "./dialogs/ColorPickerPopover";
import { isHexColor } from "frontend-shared/util/colorUtils";
import CircleIcon from "@mui/icons-material/Circle";

interface Props {
  color: string;
  setColor: (color: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  required?: boolean;
}

export const ColorField: React.FC<Props> = ({ color, setColor, onKeyUp, required }) => {
  const [colorPickerIsOpen, setColorPickerIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <IconButton
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          setColorPickerIsOpen(true);
        }}
      >
        <CircleIcon sx={{ color: isHexColor(color) ? color : "#f0f0f0" }} fontSize="large" />
      </IconButton>
      <TextField
        label="Color"
        value={color}
        variant="outlined"
        sx={{ minWidth: 250, width: "100%" }}
        color="primary"
        onChange={(e) => setColor(e.target.value)}
        onKeyUp={onKeyUp}
        error={!isHexColor(color)}
        helperText="Format: #RRGGBB"
        required={required}
      />
      <ColorPickerPopover
        id="cp"
        open={colorPickerIsOpen}
        chosenColor={color}
        anchorEl={anchorEl}
        onSelect={setColor}
        handleClose={() => setColorPickerIsOpen(false)}
      />
    </Stack>
  );
};
