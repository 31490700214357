import React from "react";
import { PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { Divider, Menu, MenuItem } from "@mui/material";
import { allTasksCompletedInTaskGroup } from "frontend-shared/store/todayPageStateUtils";

export interface TaskGroupMenuProps {
  setStartTime: (taskGroup: PlanTaskGroup) => void;
  setGrayTime: (taskGroup: PlanTaskGroup, isGrayTime: boolean) => void;
  moveIncompleteTasks: (taskGroup: PlanTaskGroup) => void;
  addTask: (taskGroup: PlanTaskGroup) => void;
  putTaskGroupIntoProject: (taskGroup: PlanTaskGroup) => void;
  makeTaskGroupIntoRoutine: (taskGroup: PlanTaskGroup) => void;
  updateRoutineFromTaskGroup: (taskGroup: PlanTaskGroup) => void;
  deleteTaskGroup: (taskGroup: PlanTaskGroup) => void;
}

interface Props extends TaskGroupMenuProps {
  taskGroup: PlanTaskGroup;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

export const TaskGroupActionMenu: React.FC<Props> = ({
  taskGroup,
  anchorEl,
  open,
  onClose,
  setStartTime,
  setGrayTime,
  moveIncompleteTasks,
  addTask,
  putTaskGroupIntoProject,
  makeTaskGroupIntoRoutine,
  updateRoutineFromTaskGroup,
  deleteTaskGroup,
}) => {
  return (
    <Menu
      id={`task-group-action-menu-${taskGroup.id}`}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": `task-group-action-button-${taskGroup.id}`,
        role: "listbox",
      }}
    >
      <MenuItem
        onClick={() => {
          addTask(taskGroup);
        }}
        data-testid="NewTaskMenuItem"
      >
        Add Task…
      </MenuItem>
      <MenuItem
        disabled={false}
        onClick={() => {
          setStartTime(taskGroup);
        }}
        data-testid="SetStartTimeMenuItem"
      >
        Set start time…
      </MenuItem>
      <MenuItem
        disabled={false}
        onClick={() => {
          setGrayTime(taskGroup, !taskGroup.isGrayTime);
        }}
        data-testid="SetGrayTimeMenuItem"
      >
        {taskGroup.isGrayTime ? "Unset as gray time" : "Set as gray time"}
      </MenuItem>
      <MenuItem
        disabled={allTasksCompletedInTaskGroup(taskGroup)}
        onClick={() => {
          moveIncompleteTasks(taskGroup);
        }}
        data-testid="MoveIncompleteTasksMenuItem"
      >
        Move incomplete tasks…
      </MenuItem>
      <MenuItem
        onClick={() => {
          putTaskGroupIntoProject(taskGroup);
        }}
        data-testid="ConnectToProjectMenuItem"
      >
        Assign to a project…
      </MenuItem>
      {taskGroup.routineId ? (
        <MenuItem
          onClick={() => {
            updateRoutineFromTaskGroup(taskGroup);
          }}
          data-testid="UpdateRoutineFromTaskGroupMenuItem"
        >
          Update routine
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            makeTaskGroupIntoRoutine(taskGroup);
          }}
          data-testid="MakeIntoRoutineMenuItem"
        >
          Make into routine…
        </MenuItem>
      )}
      <Divider />
      <MenuItem
        onClick={() => {
          deleteTaskGroup(taskGroup);
        }}
        data-testid="DeleteTaskGroupMenuItem"
      >
        Delete
      </MenuItem>
    </Menu>
  );
};
