import {
  addNewTaskToPlan,
  addTaskToPlan,
  deletePlannedTask,
  setDailyPlanId,
  setEndTimeBlockNumber,
  setStartTimeBlockNumber,
  unplanTask,
} from "frontend-shared/store/todayPageSlice";
import { addUnplannedTask, deleteUnplannedTask } from "frontend-shared/store/backlogSlice";
import { PlanTask, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import {
  requestAddNewTaskToPlan,
  requestAddTaskToPlan,
  requestUnplanTask,
} from "frontend-shared/generated/graphqlWrappers";
import { showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import { optimisticUpdateAndServerRequestWithUndo } from "frontend-shared/api/api.utils";
import { uuidForId } from "frontend-shared/util/uuidUtils";
import { TodayOptimisticAPIContext } from "./today.api.plan";

export const apiPlanTask = (apiContext: TodayOptimisticAPIContext, task: PlanTask) => {
  const { client, todayPageState, settingsState, dispatch } = apiContext;
  const oldDailyPlanId = todayPageState.dailyPlanId;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  // When you plan a task, a new group will be created for it.
  const taskGroupId = uuidForId();
  const taskGroupOrder = (todayPageState.taskGroups[todayPageState.taskGroups.length - 1]?.order ?? 0) + 1000;
  dispatch(deleteUnplannedTask({ taskId: task.id }));
  dispatch(addTaskToPlan({ taskGroupId, taskGroupOrder, task, grayIsDefault: settingsState.grayIsDefault }));

  if (!hasDailyPlan) {
    dispatch(setDailyPlanId({ dailyPlanId }));
    dispatch(setStartTimeBlockNumber({ startTimeBlockNumber: settingsState.defaultStartTimeBlockNumber }));
    dispatch(setEndTimeBlockNumber({ endTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }));
  }

  requestAddTaskToPlan(
    client,
    {
      dailyPlanId,
      date: todayPageState.date,
      taskGroupId,
      taskGroupOrder,
      taskId: task.id,
      isGrayTime: settingsState.grayIsDefault,
    },
    () => {},
    (err) => {
      dispatch(setDailyPlanId({ dailyPlanId: oldDailyPlanId }));
      dispatch(unplanTask({ taskId: task.id, removeEmptyGroup: true }));
      dispatch(addUnplannedTask({ taskId: task.id, name: task.name, order: task.order, roleId: task.roleId }));
      dispatch(showSnackbarError(err));
    }
  );
};

export const apiUnplanTask = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  task: PlanTask,
  grayIsDefault: boolean
) => {
  const { client, dispatch } = apiContext;
  const taskGroupName = taskGroup.name;
  const timeBlockCount = taskGroup.timeBlockCount;
  const completedTimeBlockCount = taskGroup.completedTimeBlockCount;
  const taskGroupOrder = taskGroup.order;
  const routineId = taskGroup.routineId;
  const projectId = taskGroup.projectId;
  const roleIdentityId = taskGroup.roleIdentityId;
  const isGrayTime = taskGroup.isGrayTime;
  const roleId = task.roleId;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: [
      unplanTask({ taskId: task.id }),
      addUnplannedTask({ taskId: task.id, name: task.name, order: task.order, roleId }),
    ],
    request: requestUnplanTask,
    variables: { taskId: task.id },
    undo: [
      addTaskToPlan({
        taskGroupId: taskGroup.id,
        taskGroupOrder,
        task,
        grayIsDefault,
        recoveryData: {
          taskGroupName,
          roleId,
          timeBlockCount,
          completedTimeBlockCount,
          routineId,
          projectId,
          roleIdentityId,
          isGrayTime,
        },
      }),
      deleteUnplannedTask({ taskId: task.id }),
    ],
  });
};

export function apiAddNewTaskToPlan(
  apiContext: TodayOptimisticAPIContext,
  name: string,
  roleId: string,
  grayIsDefault: boolean,
  setAddTaskInFlight: (inFlight: boolean) => void
) {
  const { client, todayPageState, backlogState, settingsState, dispatch } = apiContext;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const oldDailyPlanId = todayPageState.dailyPlanId;
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  // A new task will also create a new taskGroup
  const taskId = uuidForId();
  const taskGroupId = uuidForId();
  const order = (backlogState.unplannedTasks[backlogState.unplannedTasks.length - 1]?.order ?? 0) + 1000;
  const taskGroupOrder = (todayPageState.taskGroups[todayPageState.taskGroups.length - 1]?.order ?? 0) + 1000;
  const isGrayTime = grayIsDefault;

  if (!hasDailyPlan) {
    dispatch(setDailyPlanId({ dailyPlanId }));
    dispatch(setStartTimeBlockNumber({ startTimeBlockNumber: settingsState.defaultStartTimeBlockNumber }));
    dispatch(setEndTimeBlockNumber({ endTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }));
  }

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    onStart: () => setAddTaskInFlight(true),
    onEnd: () => setAddTaskInFlight(false),
    optimisticUpdate: addNewTaskToPlan({ taskGroupId, taskGroupOrder, taskId, roleId, name, order, grayIsDefault }),
    request: requestAddNewTaskToPlan,
    variables: {
      dailyPlanId,
      date: todayPageState.date,
      taskGroupId,
      taskGroupOrder,
      taskId,
      roleId,
      name,
      order,
      isGrayTime,
    },
    undo: [deletePlannedTask({ taskId, removeEmptyGroup: true }), setDailyPlanId({ dailyPlanId: oldDailyPlanId })],
  });
}
