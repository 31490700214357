import Cookies from "js-cookie";
import { formatTimerState, parseTimerState } from "frontend-shared/util/timerState";
import { TodayPageState } from "frontend-shared/store/todayPageSlice";
import { TimerType, formatTimerType, parseTimerType } from "frontend-shared/util/timerType";

export const getTimerCookies = () => {
  const timerState = parseTimerState(Cookies.get("timerState"));
  const secondsLeft = parseInt(Cookies.get("secondsLeft") || "-1");
  const timerType = parseTimerType(Cookies.get("timerType") || formatTimerType(TimerType.Work));
  const dateOfLastTickISOString = Cookies.get("dateOfLastTick") ?? new Date().toISOString();
  const timerRoleId = Cookies.get("timerRoleId") ?? "";
  return { timerState, secondsLeft, dateOfLastTickISOString, timerType, timerRoleId };
};

export const setTimerCookies = (todayPageState: TodayPageState) => {
  Cookies.set("timerState", formatTimerState(todayPageState.timerState));
  Cookies.set("secondsLeft", todayPageState.secondsLeftOnTimer.toString());
  Cookies.set("dateOfLastTick", todayPageState.dateOfLastTickISOString);
  Cookies.set("timerType", formatTimerType(todayPageState.timerType));
  Cookies.set("timerRoleId", todayPageState.timerRoleId);
};

export interface PlanToolbarCookies {
  selectedRoleIds: string[] | undefined;
  roleView: string;
  roleViewCompleted: string;
  defaultRoleId: string;
}

export const getPlanToolbarCookies = (): PlanToolbarCookies => {
  const roleView = Cookies.get("roleView") ?? "Interspersed";
  const roleViewCompleted = Cookies.get("roleViewCompleted") ?? "Normal";
  const defaultRoleId = Cookies.get("defaultRoleId") ?? "";
  const selectedRoleIds = Cookies.get("selectedRoleIds")?.split(",");
  // When we read "" cookie as an array, use [], not [""]
  if (selectedRoleIds?.length === 1 && selectedRoleIds[0] === "") {
    return { selectedRoleIds: [], roleView, roleViewCompleted, defaultRoleId };
  }
  return { selectedRoleIds, roleView, roleViewCompleted, defaultRoleId };
};

export const setPlanToolbarCookies = (cookies: PlanToolbarCookies) => {
  Cookies.set("selectedRoleIds", cookies?.selectedRoleIds?.join(",") ?? "");
  Cookies.set("roleView", cookies.roleView);
  Cookies.set("roleViewCompleted", cookies.roleViewCompleted);
  Cookies.set("defaultRoleId", cookies.defaultRoleId ?? "");
};

export const setRoleViewCookies = (roleView: string) => {};
