import React from "react";
import { TimeBlockButtons } from "./timeBlockButtons";
import { RoutinePlanTaskGroup } from "frontend-shared/util/modelTypes";

interface Props {
  routine: RoutinePlanTaskGroup;
  onTimeBlockClick: (routine: RoutinePlanTaskGroup, timeBlockIndex: number) => void;
  colorForRole: (roleId: string) => string;
  small?: boolean;
}

export const RoutineTimeBlockButtons: React.FC<Props> = ({ routine, onTimeBlockClick, colorForRole, small }) => {
  const timeBlocksToRender = Math.max(5, Math.ceil(routine.timeBlockCount / 2) * 2 + 1);

  return (
    <TimeBlockButtons
      small={small}
      timeBlockCount={routine.timeBlockCount}
      timeBlocksToRender={timeBlocksToRender}
      isGray={routine.isGrayTime}
      timeBlockOpacity={(_: number) => {
        return 1;
      }}
      onTimeBlockClick={(timeBlockIndex: number) => {
        onTimeBlockClick(routine, timeBlockIndex);
      }}
      colorForRole={() => {
        return colorForRole(routine.roleId);
      }}
    />
  );
};
