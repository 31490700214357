import React from "react";
import { DragDropContext, DropResult, Droppable } from "@hello-pangea/dnd";
import { PlanRole, PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { DraggableTaskGroup, TaskGroupLabel } from "./draggableTaskGroup";
import { Dictionary } from "@reduxjs/toolkit";
import { TaskGroupMenuProps } from "./taskGroupActionMenu";
import { TaskEditProps } from "./plannedTask";
import { TaskGroupHeaderProps } from "./draggableTaskGroupHeader";

interface Props extends TaskGroupMenuProps, TaskEditProps, TaskGroupHeaderProps {
  droppableId: string;
  taskGroups: PlanTaskGroup[];
  dropEnd: (result: DropResult) => void;
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  editingInProgress: boolean;
  inFilter: (taskGroup: PlanTaskGroup) => boolean;
  toggleTaskGroupCollapsed: (task: PlanTaskGroup) => void;
  shouldBlockUI: boolean;
  setEditingInProgress: (block: boolean) => void;
  onTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  onCompletedTimeBlockClick: (taskGroup: PlanTaskGroup, timeBlockIndex: number) => void;
  labelsForTaskGroup: (taskGroup: PlanTaskGroup) => TaskGroupLabel[];
  colorForRole: (roleId: string) => string;
  canBeGrouped: (index: number, taskGroup: PlanTaskGroup, inFilter: (taskGroup: PlanTaskGroup) => boolean) => boolean;
  selectedTaskGroupId?: string;
  onSelectTaskGroup: (taskGroupId: string) => void;
}

export const TaskGroupsWithRolesInterspersed: React.FC<Props> = (props: Props) => {
  const { droppableId, dropEnd, taskGroups, inFilter, canBeGrouped, labelsForTaskGroup } = props;
  return (
    <DragDropContext onDragEnd={(result) => dropEnd(result)}>
      <Droppable droppableId={droppableId}>
        {(provided, _) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {taskGroups.map((taskGroup, i) => (
              <DraggableTaskGroup
                key={taskGroup.id}
                {...props}
                groupTask={
                  props.groupTask !== undefined && canBeGrouped(i, taskGroup, inFilter)
                    ? (task, taskGroup) => {
                        props.groupTask?.(task, taskGroup, inFilter);
                      }
                    : undefined
                }
                taskGroup={taskGroup}
                taskGroupLabels={labelsForTaskGroup(taskGroup)}
                groupIndex={i}
                inFilter={inFilter(taskGroup)}
                isSelected={taskGroup.id === props.selectedTaskGroupId}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
