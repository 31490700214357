import React, { useState } from "react";

import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { AppLogo } from "../../components/appLogo";
import { useNavigate, useParams } from "react-router-dom";
import { useRegisterWithInvitationMutation, useUserForInviteQuery } from "frontend-shared/generated/graphql";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { setAccessToken, setSiteAdmin } from "frontend-shared/util/auth";

type Params = {
  inviteCode: string;
};

export const InvitePage: React.FC = () => {
  const navigate = useNavigate();
  const inviteCode = useParams<Params>()["inviteCode"] ?? "";

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const { data, loading, error } = useUserForInviteQuery({ variables: { inviteCode }, fetchPolicy: "network-only" });
  const [registerWithInvitation] = useRegisterWithInvitationMutation();

  if (error && errorMessage !== error.message) {
    setErrorMessage(error.message);
  } else if (loading) {
    return <React.Fragment />;
  }

  const onRegisterClick = async (client: ApolloClient<object>) => {
    try {
      const response = await registerWithInvitation({
        variables: {
          inviteCode,
          password,
        },
      });
      if (response && response.data) {
        setErrorMessage("");
        setAccessToken(response.data.registerWithInvitation.accessToken);
        setSiteAdmin(response.data.registerWithInvitation.isSiteAdmin);
        navigate("/");
        return;
      }
    } catch {
      setErrorMessage("Could not register user");
      return;
    }
  };

  const saveIsDisabled = () => {
    return password === "" || passwordConfirm === "" || password !== passwordConfirm;
  };

  return (
    <Paper sx={{ m: 8 }}>
      <Stack sx={{ p: 4 }} spacing={2}>
        <AppLogo />
        {errorMessage !== "" ? <Typography>{errorMessage}</Typography> : <React.Fragment />}
        {data ? (
          <React.Fragment>
            <Typography>
              You have been invited to the Momentum. Please provide a password to complete the registration.
            </Typography>
            <TextField
              label="e-mail"
              id="user-text-field"
              type="email"
              value={data.userForInvite.email}
              autoComplete="username"
              variant="outlined"
              sx={{ minWidth: 350 }}
              color="primary"
            />
            <TextField
              label="Name"
              value={data.userForInvite.displayName}
              variant="outlined"
              sx={{ minWidth: 350 }}
              color="primary"
            />
            <TextField
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              id="new-password-text-field"
              autoComplete="new-password"
              value={password}
              label="Password"
              variant="outlined"
              type="password"
              sx={{ minWidth: 350 }}
              required
              color="primary"
            />

            <TextField
              onChange={(e) => setPasswordConfirm(e.target.value)}
              id="confirm-password-text-field"
              autoComplete="new-password"
              value={passwordConfirm}
              label="Confirm Password"
              variant="outlined"
              type="password"
              sx={{ minWidth: 350 }}
              required
              color="primary"
            />
            <ApolloConsumer>
              {(client) => (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onRegisterClick(client);
                  }}
                  disabled={saveIsDisabled()}
                >
                  Register
                </Button>
              )}
            </ApolloConsumer>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </Stack>
    </Paper>
  );
};
