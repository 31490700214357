import {
  addRoutineToPlan,
  connectTaskGroupToRoutine,
  setDailyPlanId,
  setEndTimeBlockNumber,
  setStartTimeBlockNumber,
} from "frontend-shared/store/todayPageSlice";
import { PlanTaskGroup, RoutinePlanTaskGroup } from "frontend-shared/util/modelTypes";
import {
  requestAddRoutineToPlan,
  requestArchiveUserRoutine,
  requestMakeRoutineFromTaskGroup,
  requestSetUserRoutineDaysOfTheWeek,
  requestUpdateRoutineFromTaskGroup,
} from "frontend-shared/generated/graphqlWrappers";
import { showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import { optimisticUpdateAndServerRequestWithUndo } from "frontend-shared/api/api.utils";
import { uuidForId } from "frontend-shared/util/uuidUtils";
import { archiveRoutine, createRoutine, setRoutineDaysOfTheWeek, unarchiveRoutine, updateRoutine } from "frontend-shared/store/routinesSlice";
import { TodayOptimisticAPIContext } from "./today.api.plan";

export const apiPlanRoutine = (apiContext: TodayOptimisticAPIContext, routine: RoutinePlanTaskGroup) => {
  const { client, todayPageState, settingsState, dispatch } = apiContext;
  const hasDailyPlan = todayPageState.dailyPlanId !== "";
  const dailyPlanId = hasDailyPlan ? todayPageState.dailyPlanId : uuidForId();

  const taskGroupId = uuidForId();
  const taskGroupOrder = (todayPageState.taskGroups[todayPageState.taskGroups.length - 1]?.order ?? 0) + 1000;

  // We can't do this optimistically because we don't know the taskIds until the server responds.
  requestAddRoutineToPlan(
    client,
    {
      routineId: routine.id,
      dailyPlanId,
      date: todayPageState.date,
      taskGroupId,
      taskGroupOrder,
    },
    (result) => {
      const taskIds = result.idsInserted ?? [];
      if (taskIds.length === routine.tasks.length) {
        if (!hasDailyPlan) {
          dispatch(setDailyPlanId({ dailyPlanId }));
          dispatch(setStartTimeBlockNumber({ startTimeBlockNumber: settingsState.defaultStartTimeBlockNumber }));
          dispatch(setEndTimeBlockNumber({ endTimeBlockNumber: settingsState.defaultEndTimeBlockNumber }));
        }
        dispatch(addRoutineToPlan({ routine, taskGroupId, taskGroupOrder, taskIds }));
      } else {
        dispatch(showSnackbarError("Could not add routine to plan."));
      }
    },
    (err) => {
      dispatch(showSnackbarError(err));
    }
  );
};

export const apiMakeTaskGroupIntoRoutine = (
  apiContext: TodayOptimisticAPIContext,
  taskGroup: PlanTaskGroup,
  name: string,
  onSuccess: () => void,
  onError: (err: string) => void
) => {
  const { client, dispatch } = apiContext;
  const routineId = uuidForId();

  // We can't do this optimistically because we don't know the taskIds until the server responds.
  requestMakeRoutineFromTaskGroup(
    client,
    { routineId, name, dailyPlanTaskGroupId: taskGroup.id },
    (result) => {
      if (result.idsInserted?.length === taskGroup.tasks.length) {
        dispatch(createRoutine({ routineId, name, taskGroup, routineTaskIds: result.idsInserted }));
        dispatch(connectTaskGroupToRoutine({ taskGroupId: taskGroup.id, routineId }));
        onSuccess();
      } else {
        onError("Could not make routine from task group.");
      }
    },
    onError
  );
};

export const apiUpdateRoutineFromTaskGroup = (apiContext: TodayOptimisticAPIContext, taskGroup: PlanTaskGroup) => {
  const { client, dispatch } = apiContext;
  const routineId = taskGroup.routineId;
  if (routineId === undefined) {
    dispatch(showSnackbarError("Could not make routine from task group."));
    return;
  }
  // We can't do this optimistically because we don't know the taskIds until the server responds.
  requestUpdateRoutineFromTaskGroup(
    client,
    { routineId, dailyPlanTaskGroupId: taskGroup.id },
    (result) => {
      if (result.idsInserted?.length === taskGroup.tasks.length) {
        dispatch(updateRoutine({ routineId, taskGroup, routineTaskIds: result.idsInserted }));
      } else {
        dispatch(showSnackbarError("Could not make routine from task group."));
      }
    },
    (err) => {
      dispatch(showSnackbarError(err));
    }
  );
};

export const apiArchiveRoutine = (apiContext: TodayOptimisticAPIContext, routine: RoutinePlanTaskGroup) => {
  const { client, dispatch } = apiContext;
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: archiveRoutine({ routineId: routine.id }),
    request: requestArchiveUserRoutine,
    variables: { routineId: routine.id },
    undo: unarchiveRoutine({ routineId: routine.id }),
  });
};

export const apiSetRoutineDaysOfTheWeek = (apiContext: TodayOptimisticAPIContext, routine: RoutinePlanTaskGroup, daysOfTheWeek: number) => {
  const { client, dispatch } = apiContext;
  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: setRoutineDaysOfTheWeek({ routineId: routine.id, daysOfTheWeek }),
    request: requestSetUserRoutineDaysOfTheWeek,
    variables: { routineId: routine.id, daysOfTheWeek },
    undo: unarchiveRoutine({ routineId: routine.id }),
  });
};
