import React from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { PlanAndCompletedBlockCountResponse } from "frontend-shared/generated/graphql";
import { daysInWeekToDate, formatDateOnly } from "frontend-shared/util/dateUtils";

interface Props {
  date: Date;
  compact: boolean;
  maxTimeBlockCount: number;
  completedTimeBlockCount: number;
  grayCompletedTimeBlockCount: number;
  weekTimeBlocks: PlanAndCompletedBlockCountResponse[];
  navigateToDaysAgo: (daysAgo: number) => void;
  color: string;
}

interface Counts {
  completedTimeBlockCount: number;
  maxTimeBlockCount: number;
  grayCompletedTimeBlockCount: number;
}

const makeCounts = (
  completedTimeBlockCount: number,
  maxTimeBlockCount: number,
  grayCompletedTimeBlockCount: number,
) => {
  return {
    completedTimeBlockCount,
    maxTimeBlockCount,
    grayCompletedTimeBlockCount,
  };
};

export const DashboardWeekTimeBlockChart: React.FC<Props> = ({
  date,
  compact,
  maxTimeBlockCount,
  completedTimeBlockCount,
  grayCompletedTimeBlockCount,
  weekTimeBlocks,
  navigateToDaysAgo,
  color,
}) => {
  const emptyCounts = {} as { [key: string]: Counts };
  const daysOfWeekForChart = daysInWeekToDate(date);
  const numDays = daysOfWeekForChart.length;
  const dateToCounts = weekTimeBlocks.reduce((acc, curr) => {
    acc[curr.date] = makeCounts(curr.completedTimeBlockCount, curr.capacity, curr.grayCompletedTimeBlockCount);
    return acc;
  }, emptyCounts);
  dateToCounts[formatDateOnly(date)] = makeCounts(
    completedTimeBlockCount,
    maxTimeBlockCount,
    grayCompletedTimeBlockCount,
  );

  function countForIndex(i: number, key: keyof Counts): number {
    let dateAtIndex = new Date(date.getTime());
    dateAtIndex.setDate(date.getDate() - numDays + 1 + i);

    return dateToCounts[formatDateOnly(dateAtIndex)]?.[key] ?? 0;
  }

  const maxCount = Math.max(
    ...daysOfWeekForChart.map((_, i) => {
      return Math.max(countForIndex(i, "maxTimeBlockCount"), countForIndex(i, "completedTimeBlockCount"));
    }),
  );
  const heightPerBlock = (115.0 / Math.max(10, maxCount)) * (compact ? 0.45 : 1);
  function heightForIndex(i: number, key: keyof Counts): number {
    return countForIndex(i, key) * heightPerBlock;
  }

  function isCompleteOverMaxForIndex(i: number): boolean {
    return countForIndex(i, "completedTimeBlockCount") > countForIndex(i, "maxTimeBlockCount");
  }

  function navigateToDayAtIndex(i: number): void {
    const daysAgo = numDays - 1 - i;
    if (daysAgo === 0) {
      return;
    }
    navigateToDaysAgo(daysAgo);
  }

  return (
    <Stack>
      <Stack direction="row" sx={{ flexGrow: 1 }}>
        {daysOfWeekForChart.map((day, i) => (
          <Tooltip
            key={i}
            followCursor
            title={
              `${countForIndex(i, "maxTimeBlockCount")} Time Blocks Planned and ${countForIndex(
                i,
                "completedTimeBlockCount",
              )} Completed` +
              (countForIndex(i, "grayCompletedTimeBlockCount") > 0
                ? ` (${countForIndex(i, "grayCompletedTimeBlockCount")} are Gray)`
                : "")
            }
            placement="right"
            enterDelay={500}
            arrow
          >
            <Stack onClick={() => navigateToDayAtIndex(i)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  border: "2px solid black",
                  width: 10,
                  mx: 0.5,
                  mt: "auto",
                  position: "relative",
                  height: `${heightForIndex(i, "maxTimeBlockCount")}px`,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: color,
                    width: 14,
                    left: -2,
                    height: `${heightForIndex(i, "completedTimeBlockCount")}px`,
                    position: "absolute",
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: "#ccc",
                    width: 14,
                    left: -2,
                    height: `${heightForIndex(i, "grayCompletedTimeBlockCount")}px`,
                    position: "absolute",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    left: -4,
                    width: 18,
                    bottom: `${Math.max(
                      heightForIndex(i, "completedTimeBlockCount"),
                      heightForIndex(i, "maxTimeBlockCount"),
                    )}px`,
                  }}
                  align="center"
                >
                  {countForIndex(i, "maxTimeBlockCount")}
                </Typography>
                <Box
                  sx={{
                    borderTop: `2px solid ${
                      isCompleteOverMaxForIndex(i) && countForIndex(i, "maxTimeBlockCount") > 0 ? "white" : "black"
                    }`,
                    borderLeft: "2px solid black",
                    borderRight: "2px solid black",
                    left: -2,
                    width: "100%",
                    height: `${heightForIndex(i, "maxTimeBlockCount")}px`,
                    position: "absolute",
                  }}
                />
              </Box>
              <Typography sx={{ textAlign: "center", flexGrow: 0 }}>{compact ? "" : day}</Typography>
            </Stack>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
};
