import React from "react";
import { Stack, Typography } from "@mui/material";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { DraggableTask } from "./draggableTask";
import { PlanRole, PlanTask } from "frontend-shared/util/modelTypes";
import { Dictionary } from "@reduxjs/toolkit";

interface Props {
  unplannedTasks: PlanTask[];
  roles: Dictionary<PlanRole>;
  rolesList: PlanRole[];
  selectedRoles: Dictionary<PlanRole>;
  planTask: (task: PlanTask) => void;
  deleteTask: (task: PlanTask) => void;
  editTaskName: (task: PlanTask, newName: string) => void;
  setTaskRole: (task: PlanTask, role: PlanRole) => void;
  reorderTask: (oldIndex: number, newIndex: number) => void;
  shouldBlockPlanUI: boolean;
  shouldBlockUnplannedUI: boolean;
  setEditingInProgress: (block: boolean) => void;
}

export const UnplannedTasks: React.FC<Props> = ({
  unplannedTasks,
  roles,
  rolesList,
  selectedRoles,
  planTask,
  deleteTask,
  editTaskName,
  setTaskRole,
  reorderTask,
  shouldBlockPlanUI,
  shouldBlockUnplannedUI,
  setEditingInProgress,
}) => {
  const numberOfTasksInFilter = unplannedTasks.filter((task) => selectedRoles[task.roleId] !== undefined).length;
  return (
    <Stack sx={{ alignItems: "flex-start" }}>
      {Object.keys(selectedRoles).length > 0 ? (
        numberOfTasksInFilter === 0 ? (
          <Typography>
            <em>No tasks in filter.</em>
          </Typography>
        ) : (
          <DragDropContext
            onDragEnd={(result) => {
              if (result.destination) {
                reorderTask(result.source.index, result.destination.index);
              }
            }}
          >
            <Droppable droppableId="unplannedTasks">
              {(provided, _) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {unplannedTasks.map((task, i) => (
                    <DraggableTask
                      key={task.id}
                      task={task}
                      roles={roles}
                      rolesList={rolesList}
                      index={i}
                      planTask={planTask}
                      deleteTask={deleteTask}
                      editTaskName={editTaskName}
                      shouldBlockPlanUI={shouldBlockPlanUI}
                      shouldBlockUnplannedUI={shouldBlockUnplannedUI}
                      setEditingInProgress={setEditingInProgress}
                      setTaskRole={setTaskRole}
                      inFilter={selectedRoles[task.roleId] !== undefined}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )
      ) : (
        <Typography>
          <em>No roles selected</em>
        </Typography>
      )}
    </Stack>
  );
};
