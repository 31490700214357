import { AppDispatch } from "frontend-shared/store/store";
import { timerTick } from "frontend-shared/store/todayPageSlice";
import { TimerState } from "frontend-shared/util/timerState";

export const timerEffectFn = (isRunning: boolean, delay: number, setDate: (date: Date) => void) => {
  let interval: NodeJS.Timeout | null = null;
  if (isRunning) {
    interval = setInterval(() => {
      setDate(new Date());
    }, delay);
  }
  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
};

export const timerEffectDispatch = (timerState: TimerState, dispatch: AppDispatch) => {
  return timerEffectFn(timerState === TimerState.Running, 250, (date) => {
    dispatch(timerTick({ dateISOString: date.toISOString() }));
  });
};
