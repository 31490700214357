import { configureStore } from "@reduxjs/toolkit";
import appReducers from "./appSlice";
import backlogReducers from "./backlogSlice";
import routinesReducers from "./routinesSlice";
import snackbarReducers from "./appSnackbarSlice";
import todayPageReducers from "./todayPageSlice";
import settingsPageReducers from "./settingsSlice";
import identitiesReducers from "./identitiesSlice";

export const createStore = () =>
  configureStore({
    reducer: {
      app: appReducers,
      backlog: backlogReducers,
      routines: routinesReducers,
      snackbar: snackbarReducers,
      todayPage: todayPageReducers,
      settings: settingsPageReducers,
      identities: identitiesReducers,
    },
  });

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
