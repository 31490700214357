import React, { useState } from "react";

import { Button, Stack, TextField, Typography } from "@mui/material";
import { useLoginMutation } from "frontend-shared/generated/graphql";
import { setAccessToken, setSiteAdmin } from "frontend-shared/util/auth";
import { AppLogo } from "../../components/appLogo";
import { useNavigate } from "react-router-dom";
import { InvitationDialog } from "./login/invitationDialog";
import { requestRequestInviteForSiteUser } from "frontend-shared/generated/graphqlWrappers";
import { ApolloConsumer } from "@apollo/client";
import { useAppDispatch } from "frontend-shared/store/hooks";
import { showSnackbarError, showSnackbarSuccess } from "frontend-shared/store/appSnackbarSlice";
import { AppSnackbar } from "../../components/appSnackbar";

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [requestInvitationOpen, setRequestInvitationOpen] = useState(false);
  const [requestInvitationSent, setRequestInvitationSent] = useState(false);

  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
  });

  const { email, password } = loginInput;

  const [login] = useLoginMutation();

  const [loginError, setLoginError] = useState(false);

  const onLoginClick = async () => {
    try {
      const response = await login({
        variables: {
          email,
          password,
        },
      });
      if (response && response.data) {
        setLoginError(false);
        setAccessToken(response.data.login.accessToken);
        setSiteAdmin(response.data.login.isSiteAdmin);
        navigate("/");
        return;
      }
    } catch {
      setLoginError(true);
      return;
    }
  };

  return (
    <React.Fragment>
      <Stack sx={{ backgroundColor: "white", alignItems: "center", height: "100vh" }} spacing={2}>
        <Stack sx={{ p: 10, width: "500px" }} spacing={2}>
          {loginError ? (
            <Stack sx={{ alignItems: "center" }}>
              <Typography>Login incorrect</Typography>
            </Stack>
          ) : (
            <React.Fragment />
          )}
          <Stack sx={{ alignItems: "center" }}>
            <AppLogo />
          </Stack>
          <Stack>
            <TextField
              onChange={(e) => setLoginInput({ ...loginInput, email: e.target.value })}
              value={email}
              label="e-mail"
              variant="outlined"
              required
              color="primary"
              id="user-text-field"
              type="email"
              autoComplete="username"
            />
          </Stack>
          <Stack>
            <TextField
              onChange={(e) => setLoginInput({ ...loginInput, password: e.target.value })}
              value={password}
              label="password"
              variant="outlined"
              required
              type="password"
              color="primary"
              id="password-text-field"
              autoComplete="current-password"
            />
          </Stack>
          <Stack spacing={1}>
            <Button size="large" variant="contained" color="primary" onClick={onLoginClick}>
              Login
            </Button>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={() => {
                setRequestInvitationOpen(true);
              }}
            >
              Request an Invitation
            </Button>
            {requestInvitationSent && (
              <Typography sx={{ textAlign: "center" }}>
                Invitation request sent. Check your email (and spam folder).
              </Typography>
            )}
          </Stack>
        </Stack>
        <ApolloConsumer>
          {(client) => (
            <InvitationDialog
              open={requestInvitationOpen}
              onClose={() => {
                setRequestInvitationOpen(false);
              }}
              cancel={function (): void {
                setRequestInvitationOpen(false);
              }}
              saveAndClose={(email: string, displayName: string) => {
                requestRequestInviteForSiteUser(
                  client,
                  { email, displayName },
                  () => {
                    dispatch(showSnackbarSuccess("Invitation request sent. Check your email."));
                    setRequestInvitationSent(true);
                  },
                  (err) => {
                    dispatch(showSnackbarError(err));
                  },
                );
                setRequestInvitationOpen(false);
              }}
            />
          )}
        </ApolloConsumer>
        <AppSnackbar />
      </Stack>
    </React.Fragment>
  );
};
