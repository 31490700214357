import React, { useState } from "react";
import { Tooltip, Typography, IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { PlanTaskGroup } from "frontend-shared/util/modelTypes";
import { ModalTextField } from "../../../components/modalTextField";

interface Props {
  taskGroup: PlanTaskGroup;
  saveEdit: (value: string) => void;
  cancelEdit: () => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

export const TaskGroupName: React.FC<Props> = ({ taskGroup, saveEdit, cancelEdit, isEditing, setIsEditing }) => {
  const [groupName, setGroupName] = useState(taskGroup.name ?? "");
  return (
    <Stack direction="row" sx={{ mt: 1, ml: 1, flexGrow: isEditing ? 1 : 0 }}>
      {isEditing ? (
        <ModalTextField
          id={taskGroup.id}
          value={groupName}
          originalValue={taskGroup.name ?? ""}
          allowEmpty={true}
          onChange={function (value: string): void {
            setGroupName(value);
          }}
          saveEdit={() => {
            saveEdit(groupName);
            setIsEditing(false);
          }}
          cancelEdit={() => {
            cancelEdit();
            setIsEditing(false);
          }}
        />
      ) : (
        <>
          {taskGroup.name && (
            <Typography
              variant="h6"
              sx={{
                ml: 1,
                alignSelf: "center",
              }}
            >
              {taskGroup.name}
            </Typography>
          )}
          <Tooltip title="Name" placement="top">
            <IconButton
              sx={{
                alignSelf: "center",
                height: "40px",
              }}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};
