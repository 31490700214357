import React, { useEffect } from "react";
import { useSettingsQuery } from "frontend-shared/generated/graphql";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { clearSettingsState, initializeSettingsState, selectSettingsState } from "frontend-shared/store/settingsSlice";

export const SettingsStateLoader: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading, error } = useSettingsQuery({
    variables: {},
    fetchPolicy: "network-only",
  });
  const settingsState = useAppSelector(selectSettingsState);

  // You need an effect to deal with loading state because you want to change state
  // via a dispatch while loading. You can't do that in the render function.
  useEffect(() => {
    if (loading) {
      dispatch(clearSettingsState());
    } else if (data && !settingsState.initialized) {
      dispatch(initializeSettingsState({ data }));
    }
  }, [data, settingsState.initialized, dispatch, loading]);

  if (loading) {
    return <></>;
  } else if (error) {
    return <></>;
  } else if (!data) {
    return <></>;
  } else if (!settingsState.initialized) {
    return <></>;
  }

  return <></>;
};
