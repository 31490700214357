import { Dictionary, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { PlanRole, PlanTaskGroup, RoutinePlanTaskGroup } from "../util/modelTypes";
import { localToggleSelectedRole } from "../api/today.localApi.backlog";
import { RoutinesQuery } from "../generated/graphql";
import { nonBlankOrNull } from "../util/stringUtils";

export interface RoutinesState {
  initialized: boolean;
  routines: RoutinePlanTaskGroup[];
  roles: Dictionary<PlanRole>;
  selectedRoles: Dictionary<PlanRole>;
  defaultRoleId: string;
}

const initialState: RoutinesState = {
  initialized: false,
  routines: [],
  roles: {},
  selectedRoles: {},
  defaultRoleId: "",
};

export const routinesSlice = createSlice({
  name: "routines",
  initialState,
  reducers: {
    clearRoutinesState: (state) => {
      state.initialized = false;
      state.routines = initialState.routines;
      state.roles = initialState.roles;
      state.selectedRoles = initialState.selectedRoles;
      state.defaultRoleId = initialState.defaultRoleId;
    },
    initializeRoutinesState: (state, action: PayloadAction<{ data: RoutinesQuery; selectedRoleIds: string[] | undefined }>) => {
      const { data, selectedRoleIds } = action.payload;
      state.initialized = true;
      state.routines = action.payload.data.userRoutines.map((routine) => ({
        id: routine.id,
        name: routine.name,
        roleId: routine.role.id,
        projectId: routine.project?.id,
        roleIdentityId: routine.roleIdentity?.id,
        timeBlockCount: routine.timeBlockCount,
        isGrayTime: routine.isGrayTime,
        daysOfTheWeek: routine.daysOfTheWeek,
        archived: routine.archived,
        tasks: routine.tasks.map((task) => ({
          id: task.id,
          name: task.name,
          order: task.order,
        })),
      }));
      state.roles = data.userAccount.roles.reduce((roles, role) => {
        roles[role.id] = role;
        return roles;
      }, {} as Dictionary<PlanRole>);
      state.selectedRoles =
        selectedRoleIds === undefined
          ? state.roles
          : selectedRoleIds.reduce((roles, roleId) => {
              roles[roleId] = state.roles[roleId];
              return roles;
            }, {} as Dictionary<PlanRole>);
      state.defaultRoleId = data.userAccount.roles[0].id;
    },
    toggleRoutinesFilterRoleIdSelected: (state, action: PayloadAction<{ roleId: string }>) => {
      localToggleSelectedRole(state, action.payload.roleId);
    },
    createRoutine: (state, action: PayloadAction<{ routineId: string; name: string; taskGroup: PlanTaskGroup; routineTaskIds: string[] }>) => {
      const { routineId, name, taskGroup, routineTaskIds } = action.payload;
      const roleId = taskGroup.roleId;
      const projectId = taskGroup.projectId;
      const roleIdentityId = taskGroup.roleIdentityId;
      // find the index to keep the routines sorted by name
      const index = state.routines.findIndex((routine) => routine.name.localeCompare(name) > 0);
      const newRoutine = {
        id: routineId,
        name,
        roleId,
        projectId,
        roleIdentityId,
        timeBlockCount: taskGroup.timeBlockCount,
        isGrayTime: taskGroup.isGrayTime,
        daysOfTheWeek: 0,
        archived: false,
        tasks: taskGroup.tasks.map((task, index) => ({
          id: routineTaskIds[index],
          name: task.name,
          order: task.order,
        })),
      };
      if (index === -1) {
        state.routines.push(newRoutine);
      } else {
        state.routines.splice(index, 0, newRoutine);
      }
    },
    updateRoutine: (state, action: PayloadAction<{ routineId: string; taskGroup: PlanTaskGroup; routineTaskIds: string[] }>) => {
      const { routineId, taskGroup, routineTaskIds } = action.payload;
      const routine = state.routines.find((routine) => routine.id === routineId);
      if (routine) {
        routine.name = nonBlankOrNull(taskGroup.name) ?? routine.name;
        routine.timeBlockCount = taskGroup.timeBlockCount;
        routine.projectId = taskGroup.projectId;
        routine.roleIdentityId = taskGroup.roleIdentityId;
        routine.archived = false;
        routine.tasks = taskGroup.tasks.map((task, index) => ({
          id: routineTaskIds[index],
          name: task.name,
          order: task.order,
        }));
      }
    },
    archiveRoutine: (state, action: PayloadAction<{ routineId: string }>) => {
      const routine = state.routines.find((routine) => routine.id === action.payload.routineId);
      if (routine) {
        routine.archived = true;
      }
    },
    unarchiveRoutine: (state, action: PayloadAction<{ routineId: string }>) => {
      const routine = state.routines.find((routine) => routine.id === action.payload.routineId);
      if (routine) {
        routine.archived = false;
      }
    },
    setRoutineDaysOfTheWeek: (state, action: PayloadAction<{ routineId: string; daysOfTheWeek: number }>) => {
      const routine = state.routines.find((routine) => routine.id === action.payload.routineId);
      if (routine) {
        routine.daysOfTheWeek = action.payload.daysOfTheWeek;
      }
    },
  },
});

export const {
  clearRoutinesState,
  initializeRoutinesState,
  toggleRoutinesFilterRoleIdSelected,
  createRoutine,
  updateRoutine,
  archiveRoutine,
  unarchiveRoutine,
  setRoutineDaysOfTheWeek,
} = routinesSlice.actions;

export const selectRoutinesState = (state: RootState) => state.routines as RoutinesState;

export default routinesSlice.reducer;
