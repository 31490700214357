import { PlanRole, PlanTask } from "frontend-shared/util/modelTypes";
import { AppDispatch } from "frontend-shared/store/store";
import {
  requestAddUnplannedTask,
  requestDeleteUnplannedTask,
  requestSetUserTaskName,
  requestSetUserTaskOrder,
  requestSetUserTaskRole,
} from "frontend-shared/generated/graphqlWrappers";
import { showSnackbarError } from "frontend-shared/store/appSnackbarSlice";
import {
  addUnplannedTask,
  deleteUnplannedTask,
  reorderUnplannedTask,
  setDefaultBacklogRoleId,
  setUnplannedTaskName,
  setUnplannedTaskRole,
} from "frontend-shared/store/backlogSlice";
import { optimisticUpdateAndServerRequestWithUndo } from "frontend-shared/api/api.utils";
import { calculateTaskOrderAfterReorder } from "frontend-shared/store/todayPageStateUtils";
import { uuidForId } from "frontend-shared/util/uuidUtils";
import { TodayOptimisticAPIContext } from "./today.api.plan";

export const localSetDefaultRoleIdForBacklogAddTaskToPlan = (roleId: string, dispatch: AppDispatch) => {
  dispatch(setDefaultBacklogRoleId({ roleId }));
};

export function apiEditUnplannedTaskName(apiContext: TodayOptimisticAPIContext, task: PlanTask, newName: string) {
  const { client, dispatch } = apiContext;
  const oldName = task.name;
  dispatch(setUnplannedTaskName({ id: task.id, name: newName }));
  requestSetUserTaskName(
    client,
    { taskId: task.id, name: newName },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(setUnplannedTaskName({ id: task.id, name: oldName }));
    }
  );
}

export function apiDeleteUnplannedTask(apiContext: TodayOptimisticAPIContext, task: PlanTask) {
  const { client, dispatch } = apiContext;
  const roleId = task.roleId;
  dispatch(deleteUnplannedTask({ taskId: task.id }));
  requestDeleteUnplannedTask(
    client,
    { taskId: task.id },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(addUnplannedTask({ taskId: task.id, roleId, name: task.name, order: task.order }));
    }
  );
}

export function apiAddUnplannedTask(
  apiContext: TodayOptimisticAPIContext,
  name: string,
  roleId: string,
  setAddTaskInFlight: (inFlight: boolean) => void
) {
  const { client, dispatch, backlogState } = apiContext;
  const taskId = uuidForId();
  const order = (backlogState.unplannedTasks[backlogState.unplannedTasks.length - 1]?.order ?? 0) + 1000;

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    onStart: () => setAddTaskInFlight(true),
    onEnd: () => setAddTaskInFlight(false),
    optimisticUpdate: addUnplannedTask({ taskId, roleId, name, order }),
    request: requestAddUnplannedTask,
    variables: { taskId, roleId, name, order },
    undo: deleteUnplannedTask({ taskId }),
  });
}

export function apiReorderUnplannedTask(apiContext: TodayOptimisticAPIContext, oldIndex: number, newIndex: number) {
  const { client, dispatch, backlogState } = apiContext;
  const taskId = backlogState.unplannedTasks[oldIndex].id;
  const oldOrder = backlogState.unplannedTasks[oldIndex].order;
  const newOrder = calculateTaskOrderAfterReorder(oldIndex, newIndex, backlogState.unplannedTasks);
  dispatch(reorderUnplannedTask({ oldIndex, newIndex, newOrder }));
  requestSetUserTaskOrder(
    client,
    { taskId, order: newOrder },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(reorderUnplannedTask({ oldIndex: newIndex, newIndex: oldIndex, newOrder: oldOrder }));
    }
  );
}

export const apiSetUnplannedTaskRole = (apiContext: TodayOptimisticAPIContext, task: PlanTask, role: PlanRole) => {
  const { client, dispatch } = apiContext;
  const oldTaskRoleId = task.roleId;
  dispatch(setUnplannedTaskRole({ id: task.id, roleId: role.id }));
  requestSetUserTaskRole(
    client,
    { taskId: task.id, roleId: role.id },
    () => {},
    (err) => {
      dispatch(showSnackbarError(err));
      dispatch(setUnplannedTaskRole({ id: task.id, roleId: oldTaskRoleId }));
    }
  );
};
